import { useState } from "react";
// import { PhotoIcon } from '@heroicons/react/24/outline';

function FormUpload({ label, name, id, setValue, required }) {

    const [fileName, setFileName] = useState('(No file selected)');

    const handleFileChange = (e) => {
        setFileName(e.target.files[0].name);
        setValue(e.target.files[0]);
    }
    
    return (
        <div>
            <label htmlFor={name} className="form-label">
                {label} {required && <span className="text-gold-700">*</span>}
            </label>
            
            <div className="mt-2 flex flex-row items-center space-x-6 rounded-lg border border-dashed border-gray-900/25 dark:border-white px-4 py-3">
                {/* <PhotoIcon className="hidden md:block h-8 w-8 text-gray-300" aria-hidden="true" /> */}

                <div className="flex flex-col items-start min-w-[50%] text-wrap">
                    <p className="hidden md:block text-xs leading-5 text-slate-700 dark:text-text-dark">
                        PNG, JPG, JPEG, DOC up to 5MB
                    </p>

                    <p className={`w-[100%] text-xs leading-5 truncate ${fileName !== '(No file selected)' ? 'text-gold-700 dark:text-gold-700' : 'text-slate-700 dark:text-text-dark'}`}>
                        {fileName}
                    </p>
                </div>

                <div className="flex grow justify-end text-sm leading-6 text-gray-600">
                    <label htmlFor={name} className="relative cursor-pointer rounded-md font-semibold text-slate-700 dark:text-text-dark hover:text-indigo-500">
                        <span className="px-5 py-2 rounded-md border border-gold-500 hover:bg-gold-700 hover:text-white">Attach</span>
                        <input
                            id={id}
                            name={name}
                            type="file"
                            className="sr-only"
                            onChange={handleFileChange}
                            accept='image/*, .doc, .docx, .pdf'
                            required={required}
                        />
                    </label>
                </div>

            </div>
        </div>
    )
}

export default FormUpload