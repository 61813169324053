import { useState } from "react";
import { PaperClipIcon, DocumentTextIcon } from "@heroicons/react/24/solid";
// import { Dialog, Transition } from "@headlessui/react";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { accessFileRequest, fetchRequests } from "https/requests";
import { useDispatch } from "react-redux";
import { show } from "providers/toastSlice";
import { useUser } from "hooks";
import { useNavigate } from "react-router-dom";

function FaciLetter({ loan }) {

	const faci_letter = loan?.faci_letter[0];

	// let [isOpen, setIsOpen] = useState(false);
	const [signedFile, setSignedFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { accessToken } = useUser();
	const navigate = useNavigate();

	// function closeModal() {
	// 	setIsOpen(false);
	// }

	// function openModal() {
	// 	setIsOpen(true);
	// }

    // const docs = [
    //     { uri: require("../../utils/IMG_3163.jpeg") }, // Local File
    // ];

    // const handleRequest = async() => {

    //     let url = `${process.env.REACT_APP_STAGING_LOAN}/sign-facility-letter`;
    //     let headers = {
    //         "Authorization" : `${accessToken}`,
    //         "Content-Type": "application/json"
    //     }
    //     let data = {
    //         loanId : loan.loanId,
    //         loanType : loan.type.toLowerCase(),
    //     }

    //     try {
    //         let res = await fetchRequests(url, "post", headers, {}, data);
    //         console.log(res)

    //         if(res?.code === 401){
    //             dispatch(show({
    //                 state : true,
    //                 message : `${res?.err}`,
    //                 type : "token"
    //             }))
    //             return;
    //         }

    //         if(res?.err){
    //             dispatch(show({
    //                 state : true,
    //                 message : `${res?.err}`,
    //                 type : "err"
    //             }))
    //             return;
    //         }

    //         dispatch(show({
    //             state : true,
    //             message : `${res?.message}`,
    //             type : "success"
    //         }))

    //     } catch (error) {
    //         dispatch(show({
    //             state : true,
    //             message : `${error}`,
    //             type : "error"
    //         }))
    //     }
    // }

	const handleDownloadRequest = async() => {
		
		let url = `/loans/client/getFile`;
		let headers = {
			"Authorization" : `${accessToken}`,
			"Content-Type": "application/json"
		}

		let params = {
			loanId : loan.loanId,
			name : faci_letter
		}

		let res = await accessFileRequest(url, headers, params);

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
	}

	const handleFileChange = (e) => {
		setSignedFile(e.target.files[0]);
	}

	const handleUploadRequest = async() => {
		setIsLoading(true);
		
		let formData = new FormData();
		formData.append("facility-letter-signed", signedFile);
		formData.append("loanId", loan.loanId);
		formData.append("loanType", loan.type.toLowerCase());

		let url = `/loans/client/upload`;
		let headers = {
			"Authorization" : `${accessToken}`,
			"Content-Type": "multipart/form-data"
		}

		let res = await fetchRequests(url, "post", headers, {}, formData);

		setIsLoading(false);

		if(res?.code === 401){
			navigate("/login");
			dispatch(show({
				state : true,
				message : `Your session has expired. Please login to continue.`,
				type : "token"
			}))
			return;
		}

		if(res?.err){
			dispatch(show({
				state : true,
				message : `${res?.err}`,
				type : "error"
			}))
			return;
		}

		dispatch(show({
			state : true,
			message : `${res?.message}`,
			type : "success"
		}))
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	}

	return (
		<>
			<div className="mb-10">
				<div className="px-4 sm:px-0">
					<h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-white/[.8]">
						Facility Letter
					</h3>
					<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-white/[.5]">
						Download, sign and re-upload the signed facility letter.
					</p>
				</div>

				<div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
					<dd className="mt-2 text-sm text-gray-900 dark:text-white/[.8] sm:col-span-3 sm:mt-0">
						<ul className="divide-y divide-gray-100 dark:divide-white/[.2] rounded-md border border-gray-200  dark:border-white/[.2]">
							<li className="flex flex-col items-center py-4 pl-4 pr-5 text-sm leading-6 gap-8">
								<div className="flex items-center justify-between w-full">
									<div className="flex w-0 flex-1 items-center">
										<PaperClipIcon
											className="h-5 w-5 flex-shrink-0 text-gray-400"
											aria-hidden="true"
										/>
										<div className="ml-4 flex min-w-0 flex-1 gap-2">
											<span className="truncate font-medium">
												{faci_letter}
											</span>
										</div>
									</div>
									<div className="ml-4 flex-shrink-0 space-x-6">
										<button type="button" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={handleDownloadRequest}>
											Download
										</button>
										{/* <button
											type="button"
											className="font-medium text-indigo-600 hover:text-indigo-500"
											onClick={openModal}
										>
											Preview
										</button>
										<button
											type="button"
											className="font-medium text-indigo-600 hover:text-indigo-500"
											onClick={handleRequest}
										>
											Sign and Submit
										</button> */}
									</div>
								</div>

								{/* this is the facility letter upload */}
								<div className="flex items-center justify-between w-full">
									<div className="flex w-0 flex-1 items-center">

										<div>
											<label htmlFor="facility-letter-signed" className="form-label">
												Upload Signed Facility Letter
											</label>

											<div className="flex items-center space-x-6">
												<div className="mt-2 flex items-center justify-center space-x-6 rounded-lg border border-dashed border-gray-900/25 dark:border-white px-6 py-2">
													<DocumentTextIcon className="mx-auto h-6 w-6 text-gray-300" aria-hidden="true" />
													<div className="flex text-sm leading-6 text-gray-600">
														<label htmlFor="facility-letter-signed" className="relative cursor-pointer rounded-md font-semibold text-slate-700 dark:text-text-dark hover:text-indigo-500">
															<span>Upload a file</span>
															<input
																id="facility-letter-signed"
																name="facility-letter-signed"
																type="file"
																className="sr-only"
																onChange={handleFileChange}
																accept='.doc, .docx, .pdf'
																required={true}
															/>
														</label>
													</div>
													<p className="text-xs leading-5 text-slate-700 dark:text-text-dark">
														DOC, DOCX and PDF up to 5MB
													</p>
												</div>
												
												<p className="text-gray-500">
													{signedFile ? signedFile.name : "No file chosen"}
												</p>
											</div>
										</div>


									</div>
									<div className="ml-4 flex items-center flex-shrink-0 space-x-6">
										{isLoading && (
											<svg className={`animate-spin mx-auto h-4 w-4 text-gray-600`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
										)}

										<button type="button" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={handleUploadRequest}>
											Upload
										</button>
									</div>
								</div>
							</li>
						</ul>
					</dd>
				</div>
			</div>

			{/* <Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-30" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-black/25" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                                    <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />

									<div className="mt-4">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={closeModal}
										>
											Got it, thanks!
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition> */}
		</>
	);
}

export default FaciLetter;
