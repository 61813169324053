import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user : null,
    idToken : null,
    accessToken : null,
    refreshToken : null,
}

export const userSlice = createSlice({
    name : "user",
    initialState,
    reducers : {
        login : (state, action) => {
            state.user = action.payload.client;
            state.idToken = action.payload.idToken;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        logout : (state) => {
            state.user = null;
            state.idToken = null;
            state.accessToken = null;
            state.refreshToken = null;
            localStorage.removeItem("persist:root");
        },
        reauthorize : (state, action) => {
            state.idToken = action.payload.idToken;
            state.accessToken = action.payload.accessToken;
        },
        updateUserInfo : (state, action) => {
            state.user = action.payload;
        }
    }
});


export const { login, logout, reauthorize, updateUserInfo } = userSlice.actions;

export const getUser = (state) => state.user;

export const getAccessToken = (state) => state.accessToken;

export default userSlice.reducer;