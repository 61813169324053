import { useState } from 'react';
import { Pagination, RepayDates } from 'components'; 
import { dateToHumanFormat, numberFormatter } from 'components/misc/constants';

function PaymentDetails({ loan }) {

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const arrOfTransactions = loan?.transactions?.filter(l => l?.type !== "disburse");
    const currentRecords = arrOfTransactions.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(arrOfTransactions?.length / recordsPerPage);

    return (
        <div>
            <div className="px-4 sm:px-0">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-white/[.8]">Repayment dates</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-white/[.5]">Shows the dates on which payments are to be made.</p>
            </div>
            <div className="mt-6 mb-10 border-t border-gray-100 dark:border-white/[.2]">
                <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-4 py-4">
                    {loan?.standingOrderDates && loan?.standingOrderDates.length > 0 ? (
                        <RepayDates dates={loan?.standingOrderDates} />
                    ) : (
                        <RepayDates dates={loan?.paymentDates} />
                    )}
                </div>
            </div>


            <div className="px-4 sm:px-0">
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-white/[.8]">Payment transactions</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-white/[.5]">Details on the loan repayment transactions.</p>
            </div>
            <div className="mt-6 mb-10 border-t border-gray-100 dark:border-white/[.2]">
                
                <div className="-mx-4 sm:-mx-8 px-4 py-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden border dark:border-white/[.2]">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 dark:text-slate-200 uppercase border-b dark:bg-transparent dark:border-white/[.2]'>
                                <tr>
                                    <th scope="col" className='px-4 py-3'>Transaction ID</th>
                                    <th scope="col" className='px-4 py-3'>Payment date</th>
                                    <th scope="col" className='px-4 py-3'>Amount (GHS)</th>
                                </tr>
                            </thead>

                            <tbody>
                                {currentRecords && currentRecords?.length > 0 ? (
                                    currentRecords?.map(item => (
                                        <tr key={item?.transactionId} className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-semibold tracking-wide text-gray-500 dark:text-slate-400">
                                            <td className='px-4 py-3'>{item?.transactionId}</td>
                                            <td className='px-4 py-3'>{dateToHumanFormat(item?.createdAtms)}</td>
                                            <td className='px-4 py-3'>GHC {numberFormatter(item?.amount)}</td>
                                        </tr>
                                    ))
                                ) : (
                                <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-semibold tracking-wide text-gray-500 dark:text-slate-200">
                                    <td colSpan="6" className='px-4 py-3 text-center font-medium'>
                                        No payments made yet. 
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        {(currentRecords && currentRecords?.length > 0) && 
                            <Pagination 
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                length={arrOfTransactions?.length}
                            />
                        }
                    </div>
                </div>

            </div>


        </div>
    )
}

export default PaymentDetails