import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { sidebarLinks } from 'components/misc/constants';
import { useUser } from 'hooks';

function Aside() {

    const { user } = useUser();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const currentPath = path[1];
    const updatedLinks = (user?.isVerified && user?.idNumber !== undefined) ? sidebarLinks : 
        (user?.idNumber !== undefined && user?.idNumber !== "") ? sidebarLinks : 
        sidebarLinks.filter(link => link.text !== 'Apply for Loan');
    // const activeLink = sidebarLinks.find((link) => link.path === `/${currentPath}`);
    // console.log(activeLink)

    return (
        <>
        {/* Desktop Sidebar */}
        <aside id='sidebar' aria-label='Sidebar' className='w-64 flex flex-col shrink-0 h-[100%] fixed left-0 right-0 z-20 duration-75 transition ease-in-out hidden lg:flex'>
            <div className='pt-0 dark:bg-dark-bg bg-white border-r border-light-border dark:border-hover-bg flex-col flex-1 min-h-0 relative'>
                <div className='pt-5 pb-4 px-3 overflow-y-auto flex flex-1 flex-col'>
                    <ul className='pb-2 space-y-2'>
                        {updatedLinks.map((link, index) => (
                            <li key={index}>
                                <Link to={link.path} title={link.text} className={`p-2 rounded-md flex items-center text-base leading-6 font-normal {} ${'/'+ currentPath === link.path ? 'text-gold-700 bg-hover-link dark:bg-hover-link dark:text-text-color' : 'text-text-color dark:text-text-dark hover:bg-hover-link dark:hover:text-text-color'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d={link.icon} />
                                    </svg>

                                    {link.text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </aside>
        {/* Desktop Sidebar */}

        {/* Mobile Sidebar */}
        <aside id='sidebar-mobile' aria-label='Sidebar Mobile' className='mobile-menu hidden lg:hidden'>
            <div className='pt-5 pb-4 px-1.5 overflow-y-auto flex flex-1 flex-col'>
                <ul className='pb-2 space-y-2'>
                    {updatedLinks.map((link, index) => (
                        <li key={index}>
                            <Link to={link.path} title={link.text} className={`p-2 rounded-md flex items-center text-base leading-6 font-normal {} ${'/'+ currentPath === link.path ? 'text-gold-700 bg-hover-link dark:bg-hover-link dark:text-text-color' : 'text-text-color dark:text-text-dark hover:bg-hover-link dark:hover:text-text-color'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d={link.icon} />
                                </svg>

                                {link.text}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
        {/* Mobile Sidebar */}
        </>
    )
}

export default Aside