import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'

function Business({ businesses, setSelected }) {

    const onSelected = (selected) => {
        let business = {
            _id: selected._id,
            name: selected.name,
            owner: selected.owner
        }

        setSelected(business)
    }

    return (
        <RadioGroup onChange={onSelected}>
            {/* <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label> */}
            <div className="space-y-2">
                {businesses && businesses.length > 0 ? (
                    businesses.map((item) => (
                        <RadioGroup.Option
                            key={item.name}
                            value={item}
                            className={({ active, checked }) =>
                            `${
                                active
                                ? ''
                                : ''
                            }
                            ${checked ? 'bg-sky-900/75 text-white dark:border-transparent' : 'bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100'}
                                relative flex cursor-pointer rounded-lg px-5 py-4 border border-gray-300/[.7]`
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                            <div className="text-sm">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium text-base mb-1  ${
                                                    checked ? 'text-white' : 'text-gray-900 dark:text-slate-200'
                                                    }`}
                                                >
                                                    {item.name}
                                                </RadioGroup.Label>
                                                <RadioGroup.Description
                                                    as="span"
                                                    className={`inline ${
                                                    checked ? 'text-sky-100' : 'text-gray-500 dark:text-gray-400'
                                                    }`}
                                                >
                                                    <span>
                                                    {item.owner}
                                                    </span>{' '}
                                                    <span aria-hidden="true">&middot;</span>{' '}
                                                    <span className='capitalize'>{item.sector}</span>
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        {checked ? (
                                            <div className="shrink-0 text-sky-900/75 rounded-full p-1 bg-white dark:bg-white dark:text-gray-700 dark:rounded-full dark:p-1">
                                                <CheckIcon className="h-6 w-6" />
                                            </div>
                                        ) : (
                                            <div className="w-6 h-6 border border-gray-300/[.7] rounded-full shrink-0 text-white dark:bg-white dark:text-gray-700 dark:rounded-full dark:p-1 p-1">
                                            </div>   
                                        )}
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))
                ) : (
                    <div className='relative flex rounded-lg px-5 py-4 border border-gray-300/[.7]'>
                        <p>No registered businesses yet!</p>
                    </div>
                )}
            </div>
        </RadioGroup>
    )
}

export default Business