import { useState } from 'react'
import { FormUpload } from 'components'

function OtherDocsUpload({ setValue }) {

    const [counter, setCounter] = useState(0);
    const [files, setFiles] = useState([])

    const handleNewFile = () => {
        setCounter(counter + 1);
        let newFile = { 
            id: `otherDoc-${counter + 1}`, 
            name: `otherDoc-${counter + 1}`
        }
        setFiles([...files, newFile])
    }

    const handleFileUpload = (file) => {
        setValue(prevValue => [...prevValue, file]);
    }

    return (
        <>
            {files && files.length > 0 && (
                files.map((file, index) => (
                    <FormUpload 
                        key={index}
                        label={`Other Document ${index + 1}`}
                        name={file.name}
                        id={file.id}
                        setValue={handleFileUpload}
                    />
                )
            ))}


            <div className='flex items-end'>
                <button type='button' className='border border-dashed border-gray-900/25 dark:border-white px-4 py-3 rounded-lg h-[66px] w-full' onClick={handleNewFile}>
                    Add file
                </button>
            </div>
        </>
    )
}

export default OtherDocsUpload