import { useState } from 'react'
import Logo from "../../assets/images/logo.png"
import banner from "../../assets/images/banner.jpeg";
import { FormInput, FormSelect, Button, PasswordChecker } from "components";
import { validateLogin, validateRegister } from 'components/misc/functions';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { Link, useNavigate } from 'react-router-dom';
import { educationOptions, genderOptions, nationalityOptions, networkOptions } from 'components/misc/constants';
import { postRequest } from 'https/requests';
import { formatName } from 'components/misc/constants';

function Register() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otherNames, setOtherNames] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [pob, setPob] = useState('');
    const [address, setAddress] = useState('');
    const [nearestLandmark, setNearestLandmark] = useState('');
    const [postGPS, setPostGPS] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [education, setEducation] = useState('');
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [network, setNetwork] = useState('');
    const [nationality, setNationality] = useState('');
    const [strength, setStrength] = useState(null); 
    const [processing, setProcessing] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        if(password !== passwordConfirm){
            dispatch(show({
                state : true,
                message : `Passwords do not match`,
                type : "error"
            }))
            setProcessing(false);
            return;
        }

        const errors = validateLogin(phone, password);
        for (const [key, value] of Object.entries(errors)) {
            console.log(`${key} error`)
            dispatch(show({
                state : true,
                message : `${value}`,
                type : "error"
            }))
            return;
        }

        const otherErrors = validateRegister(otherNames, surname, email, pob, address, nearestLandmark, postGPS, education, dob, nationality, password);
        for (const [key, value] of Object.entries(otherErrors)) {
            console.log(`${key} error`)
            dispatch(show({
                state : true,
                message : `${value}`,
                type : "error"
            }))
            return;
        }

        let data = { otherNames : formatName(otherNames), surname: formatName(surname), email, pob, address, nearestLandmark, postGPS, password, education, gender, phone, dob, network, nationality }

        let res = await postRequest(`${process.env.REACT_APP_STAGING_AUTH}/register`, data);
        setProcessing(false);
        
        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        dispatch(show({
            state : true,
            message : `${res?.message}`,
            type : "success"
        }))

        setTimeout(() => {
            setProcessing(false);
            navigate("/login")
        }, 3000);
    }

    return (
        <div className='min-h-[100vh] min-w-[100vw] flex bg-gray-50 dark:bg-dark-bg relative'>

            <div className='w-full lg:w-1/2 flex flex-col items-center py-12 max-h-screen overflow-y-auto'>
                <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mb-6' />

                <div className='w-[90%] md:w-[75%] lg:w-[80%] shadow-sm rounded-lg h-auto p-6 space-y-6 bg-white dark:bg-dark-bg'>
                    <h4 className='dark:text-slate-200 text-display font-medium text-3xl text-center'>
                        Create an account
                    </h4>

                    <form id="admin-login" onSubmit={handleRequest} className='grid grid-cols-1 md:grid-cols-2 gap-6 px-2'>
                        <FormInput 
                            label='First name'
                            type='text'
                            name='firstname'
                            id='firstname'
                            placeholder='Enter your first name'
                            value={otherNames}
                            setValue={setOtherNames}
                            required={true}
                        />
                        
                        <FormInput 
                            label='Surname'
                            type='text'
                            name='surname'
                            id='surname'
                            placeholder='Enter your surname'
                            value={surname}
                            setValue={setSurname}
                            required={true}
                        />
                        
                        <FormInput 
                            label='Email'
                            type='email'
                            name='email'
                            id='email'
                            placeholder='Enter your email address'
                            value={email}
                            setValue={setEmail}
                            required={true}
                        />
                        
                        <FormInput 
                            label='Phone Number'
                            inputMode='numeric'
                            pattern='[0-9]*'
                            name='phone'
                            id='phone'
                            placeholder='Enter your phone number'
                            value={phone}
                            setValue={setPhone}
                            required={true}
                        />

                        <FormSelect 
                            label='Mobile Network'
                            name='network'
                            id='network'
                            content={networkOptions}
                            value={network}
                            setValue={setNetwork}
                            required={true}
                        />
                        
                        <FormSelect 
                            label='Education'
                            name='education'
                            id='education'
                            content={educationOptions}
                            value={education}
                            setValue={setEducation}
                            required={true}
                        />
                        
                        <FormSelect 
                            label='Gender'
                            name='gender'
                            id='gender'
                            content={genderOptions}
                            value={gender}
                            setValue={setGender}
                            required={true}
                        />

                        <FormInput 
                            label='Date of birth'
                            type='date'
                            name='dob'
                            id='dob'
                            value={dob}
                            setValue={setDob}
                            required={true}
                        />

                        <FormInput 
                            label='Place of birth'
                            type='text'
                            name='pob'
                            id='pob'
                            placeholder='Enter your place of birth'
                            value={pob}
                            setValue={setPob}
                            required={true}
                        />

                        <FormSelect 
                            label='Nationality'
                            name='nationality'
                            id='nationality'
                            content={nationalityOptions}
                            value={nationality}
                            setValue={setNationality}
                            required={true}
                        />

                        <FormInput 
                            label='Home Address'
                            type='text'
                            name='address'
                            id='address'
                            placeholder='Enter your home address'
                            value={address}
                            setValue={setAddress}
                            required={true}
                        />

                        <FormInput 
                            label='Nearest Landmark'
                            type='text'
                            name='nearestLandmark'
                            id='nearestLandmark'
                            placeholder='Enter your home address'
                            value={nearestLandmark}
                            setValue={setNearestLandmark}
                            required={true}
                        />

                        <div className='col-span-full'>
                            <FormInput 
                                label='Digital Address'
                                type='text'
                                name='postGPS'
                                id='postGPS'
                                placeholder='Enter your GhanaPost GPS address eg. GA-543-1234'
                                value={postGPS}
                                setValue={setPostGPS}
                                required={true}
                            />
                        </div>

                        <FormInput 
                            label='Password'
                            type='password'
                            name='password'
                            id='password'
                            value={password}
                            setValue={setPassword}
                            required={true}
                        />

                        <FormInput 
                            label='Confirm Password'
                            type='password'
                            name='confirm_password'
                            id='confirm_password'
                            value={passwordConfirm}
                            setValue={setPasswordConfirm}
                            required={true}
                        />

                        <div className='col-span-full -mt-1'>
                        {password !== "" && (
                            <PasswordChecker 
                                password={password}
                                strength={strength}
                                setStrength={setStrength}
                            />
                        )}
                        </div>

                        <div className='col-span-2'>
                            <Button 
                                btnText={"Sign up"}
                                btnType={"submit"}
                                btnClasses={"form-button"}
                                processing={processing}
                            />
                        </div>
                    </form>
                </div>

                <Link to="/login" className='mt-6'>
                    Already have an account? <span className='dark:text-slate-300'>Login</span>
                </Link>
            </div>

            <div className='hidden lg:block w-1/2'>
                <img src={banner} alt="logo" className='w-full h-full object-cover' />
            </div>

        </div>
    )
}

export default Register