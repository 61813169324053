import { useState, useEffect, useRef } from 'react'

function OTPInput({ len, setInput }) {

    const length = len;

    const [otp, setOtp] = useState(new Array(length).fill(''))
    const inputRef = useRef([])

    useEffect(() => {
        if(inputRef.current[0]){
            inputRef.current[0].focus()
        }
    }, [])

    const handleChange = (e, index) => {
        const val = e.target.value;
        if(isNaN(val)) return false;

        const newOtp = [...otp];

        newOtp[index] = val.substring(val.length - 1);

        setOtp(newOtp);

        const otpValue = newOtp.join('');
        if(otpValue.length === length){
            setInput(otpValue)
        }

        if(val && index < length - 1 && inputRef.current[index + 1]){
            inputRef.current[index + 1].focus();
        }
        
    }

    const handleClick = (index) => {
        inputRef.current[index].setSelectionRange(1, 1);

        if(index > 0 && !otp[index - 1]){
            inputRef.current[otp.indexOf("")].focus();
        }
    }

    const handleKeyDown = (index, e) => {
        if(e.key === "Backspace" && !otp[index] && index > 0 && inputRef.current[index - 1]){
            inputRef.current[index - 1].focus();
        }
    }

    return (
        <div className='flex justify-start items-center'>
            {otp?.map((data, index) => (
                <input 
                    key={index}
                    ref={el => inputRef.current[index] = el}
                    type="text"
                    name={`otp-${index}`}
                    id={`otp-${index}`}
                    value={data}
                    onChange={(e) => handleChange(e, index)}
                    onClick={() => handleClick(index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className='form-input text-center w-15 h-12 mx-1'
                />
            ))}
        </div>
    )
}

export default OTPInput