import { Fragment } from 'react'
import icon from "../../assets/images/144x.png";
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { navLinks } from 'components/misc/constants';
import { Bars3CenterLeftIcon } from "@heroicons/react/24/solid";
import { useDispatch } from 'react-redux';
import { useUser } from 'hooks';
import { logout } from 'providers/userSlice';
import { hide } from 'providers/toastSlice';
import { getFirstCharacter } from 'components/misc/functions';

function Topbar() {

    const { user } = useUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleMobileMenu = () => {
        const sidebar = document.getElementById('sidebar-mobile');
        if(sidebar.classList.contains('hidden') || sidebar.classList.contains('hide-mobile-menu')){
            sidebar.classList.remove('hidden');
            sidebar.classList.remove('hide-mobile-menu')
        } else {
            sidebar.classList.add('hide-mobile-menu');
        }
    }

    const handleLogout = () => {
        navigate('/login', { replace : true });
        dispatch(logout());
        dispatch(hide());
    }

    return (
        <nav className='fixed z-30 w-full border-b border-light-border dark:border-hover-bg dark:bg-dark-bg bg-white'>
            <div className='px-4 py-3'>
                <div className="justify-between flex items-center">
                    <div className="flex items-center">
                        <button className='lg:hidden mr-4 p-1.5 rounded-md text-icon-dark bg-transparent hover:bg-hover-link dark:hover:bg-slate-100/[.2] dark:hover:text-white/[.4]' onClick={toggleMobileMenu}>
                            <Bars3CenterLeftIcon 
                                className='w-6 h-6'
                            />
                        </button>

                        <Link to='/' title='Home' className='flex items-center space-x-2'>
                            <img src={icon} alt='CedisPay Icon' className='w-8 h-8 rounded-full' />
                            <span className='font-medium text-lg uppercase dark:text-white'>CedisPay</span>
                        </Link>
                    </div>

                    <div>
                        <Menu as="div">
                            <div>
                            <Menu.Button>
                                <div className='p-1 bg-slate-200 w-10 h-10 rounded-full flex items-center justify-center'>
                                    {`${getFirstCharacter(user?.otherNames)}${getFirstCharacter(user?.surname)}`}
                                </div>
                            </Menu.Button>
                            </div>
                            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="absolute right-[20px] mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg dark:bg-dark-bg dark:border dark:border-dark-bg/[.2]">
                                    <div className="px-1 py-1 ">
                                        <Menu.Item>
                                            <span className='block px-4 py-2 text-gray-700 dark:text-white'>
                                                <p className='text-sm font-medium'>
                                                    {`${user.otherNames} ${user.surname}`}
                                                </p>
                                                <p className='text-xs'>
                                                    {user.email}
                                                </p>
                                            </span>
                                        </Menu.Item>
                                        {navLinks.map((link, index) => (
                                            <Menu.Item key={index}>
                                                {({ active }) => (
                                                    <Link to={link.path} className={`${active ? 'bg-gray-200 dark:bg-gray-400 rounded-md' : ''} block px-4 py-2 text-sm text-gray-700 dark:text-white`}>
                                                        {link.text}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ))}
                                        <Menu.Item>
                                            <span onClick={handleLogout} className={`block px-4 py-2 text-sm text-gray-700 dark:text-white cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-400 hover:rounded-md`}>
                                                Logout
                                            </span>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Topbar