import { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { PhotoIcon } from '@heroicons/react/24/outline';
import { fileSize } from 'components/misc/functions';
import { Camera } from "components";

function Photo({ label, name, value, setValue }) {

    let [isOpen, setIsOpen] = useState(false);
    const [preview, setPreview] = useState(null);
	// const handleFileInput = useRef(null);

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    // const handleClick = () => {
	// 	handleFileInput.current.click();
	// }

    // const handleImageSet = (e) => {
	// 	const file = e.target.files[0];
	// 	setValue(file);

	// 	// Preview image
	// 	if(file){
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => {
    //             setPreview(reader.result);
    //         }
    //     }
	// }

    return (
        <>
        <div className="col-span-full">
            <p className="form-label">
                {label} 
            </p>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-white px-6 py-10">
                <div className="text-center">
                    {preview === null ? (
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                        ) : (
                        <img src={preview} alt={`${name} of GhanaCard`} className='w-72 object-fit object-cover mx-auto' />
                    )}
                    <div className="mt-4 flex flex-col items-center justify-center text-sm leading-6 text-gray-600">
                        {/* <p className="relative cursor-pointer rounded-md font-semibold text-slate-700 dark:text-slate-600 hover:text-gold-500 px-3 py-1.5 bg-slate-100">
                            <button type="button" onClick={handleClick}>
                                {value === "" ? "Take a photo" : "Change photo"}
                            </button>
                        </p> */}
                        <p className="relative cursor-pointer rounded-md font-semibold text-slate-700 dark:text-slate-600 hover:text-gold-500 px-3 py-1.5 bg-slate-100">
                            <span onClick={openModal}>
                                {value === "" ? "Take a photo" : "Change photo"}
                            </span>
                        </p>

                        {value && (
                            <p className='min-w-[50%] max-w-auto mx-auto mt-5 text-xs truncate dark:text-slate-400'>
                                {value.name} - {fileSize(value.size)}
                            </p>
                        )}
                    </div>
                </div>
            </div>

            {/* <input ref={handleFileInput} className="hidden" type="file" accept="image/*" capture={name !== "selfie-photo" ? "environment" : "user"} onChange={handleImageSet} /> */}
            
            {name === "selfie-photo" &&
                <p className="px-3 py-1.5 bg-slate-100 text-slate-700 text-center text-sm mt-3 rounded-md">
                    Please make sure your face is 90% of the view finder of the camera and you are in a bright environment with your face well lit.
                </p>
            }
        </div>

        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-30" onClose={closeModal}>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-3 text-center">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all h-[90%]">
                            <Camera 
                                name={name}
                                setPreview={setPreview}
                                setValue={setValue}
                            />

                        <div className="mt-4 space-y-6">
                            {name === "selfie-photo" &&
                                <p className="bg-sky-400 text-white py-2 px-4 rounded-md text-sm">
                                    Please make sure your face is 90% of the view finder of the camera and you are in a bright environment with your face well lit.
                                </p>
                            }
                            <button type="button" className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2" onClick={closeModal}>
                            Close!
                            </button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
        </>
    )
}

export default Photo