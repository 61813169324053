import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "components";
import { base64ToFileConverter } from "components/misc/functions";

function Camera({ name, setPreview, setValue }) {

    const cameraRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [imageConfig, setImageConfig] = useState({
		facingMode : {
			exact : "environment"
		}
	})

    const captureImage = useCallback(() => {
        // const img = cameraRef.current.getScreenshot({ width : 600, height : 600 });
        const img = cameraRef.current.getScreenshot({ width : 720, height : 576 });
        setImgSrc(img);
        setPreview(img);

        // convert base64 img to file
        const file = base64ToFileConverter(img, `${name}.jpeg`);
        setValue(file)
    }, [cameraRef, setValue, name, setPreview]);

    const retakeImage = () => {
        setImgSrc(null);
        setValue("");
    };


	useEffect(() => {
        function isMobile() {
            const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            return regex.test(navigator.userAgent);
        }
    
        if (isMobile()) {
            console.log("Mobile device detected");
            setImageConfig({
                facingMode : {
                    // exact : (name === "selfie-photo") ? "user" : "environment"
                    exact : "environment"
                }
            })
        } else {
            console.log("Desktop device detected");
            setImageConfig({
                facingMode : "user"
            })
        }

        isMobile();
    }, [name])

    return (
        <>
        {imgSrc ? (
            <img src={imgSrc} className="w-full h-auto object-fit object-cover" alt={`${name} of GhanaCard`} />
        ) : (
        <div className="relative flex justify-center items-center h-[600px]">
            <Webcam 
                // height={600} 
                // width={"100%"} 
                ref={cameraRef}
                mirrored={false}
                videoConstraints={{
                    facingMode : imageConfig.facingMode
                }}
                screenshotFormat="image/jpeg"
                className="absolute top-0 left-0 w-full h-[600px] object-cover rounded-lg"
            />
            {name === "selfie-photo" && ( 
                <div className="relative z-10 w-[18.25rem] h-[23.6rem] md:w-[21.38rem] md:h-[26.8rem] border-4 border-white rounded-[50%/60%_60%_40%_40%] rotate-180" />
            )}
            <div className="absolute bottom-5 flex justify-center bg-black/25 px-3 py-1 rounded-lg">
                <p className="text-white">
                    {name === "selfie-photo" && `Kindly position your face in the oval`}
                </p>
            </div>
            {/* {name === "selfie-photo" ? ( 
                <div className="relative z-10 w-[18.25rem] h-[23.6rem] md:w-[21.38rem] md:h-[26.8rem] border-4 border-white rounded-[50%/60%_60%_40%_40%] rotate-180" />
            ) : (
                <div className="relative z-10 w-[18.8rem] h-[11.8rem] md:w-[30rem] md:h-[19rem] border-4 border-white" />
            )}
            <div className="absolute bottom-5 flex justify-center bg-black/25 px-3 py-1 rounded-lg">
                <p className="text-white">
                    {name === "selfie-photo" ? `Kindly position your face in the oval` : `Kindly position the card in the rectangle`}
                </p>
            </div> */}
        </div>
        )}

        <div className="inline-flex items-center gap-4 mt-6">
            <Button 
                btnText={"Capture photo"}
                btnType={"button"}
                btnClasses={"px-3 py-1.5 bg-slate-100 rounded-md text-slate-700"}
                btnFunction={captureImage}
            />

            {imgSrc && (
                <Button 
                    btnText={"Retake photo"}
                    btnType={"button"}
                    btnClasses={"px-3 py-1.5 bg-slate-100 rounded-md text-slate-700"}
                    btnFunction={retakeImage}
                />
            )}
        </div>
        </>
    )
}

export default Camera