import { useState } from 'react';
import { Business, BusinessReg, Loader } from "components";
import { useBusinesses } from "hooks";

function BusInfo({ setBusinessSelect }) {

    const { isLoading, businesses } = useBusinesses();
    const [isOpened, setIsOpened] = useState(false);
    const [selected, setSelected] = useState();

    const handleToggle = () => {
        setIsOpened(!isOpened);
    }

    const setBusiness = () => {
        setBusinessSelect(selected);
    }

    if(isLoading){
        return (
            <Loader />
        )
    }

    return (
        <div className='display_card space-y-6'>
            <h2 className='text-lg font-medium dark:text-slate-200'>Select a business</h2>

            <div className='grid'>
                <Business businesses={businesses} setSelected={setSelected} />
            </div>

            <div className='flex gap-4 items-center'>
                <button className='transparent-button' onClick={handleToggle}>
                    Add Business
                </button>

                {/* <button className='px-3 py-2 text-white bg-gold-700 hover:bg-gold-500 text-sm rounded-md disabled:cursor-not-allowed disabled:bg-lighter-blue' disabled={!selected} onClick={setBusiness}>
                    Continue
                </button> */}

                <button className='inline-flex justify-center rounded-md text-sm font-semibold py-2.5 px-4 bg-gold-700 text-white hover:bg-gold-500 disabled:cursor-not-allowed disabled:bg-lighter-blue' disabled={!selected} onClick={setBusiness}>
                    Continue
                </button>
            </div>

            {isOpened && <BusinessReg />}
        </div>
    )
}

export default BusInfo