// import { useMemo } from 'react';
import { Outlet, Navigate } from "react-router-dom";
import { useUser } from 'hooks';

function ProtectedRoute() {
    const { user } = useUser() ;
    // const { user } = useMemo(() => useUser(), []) ;
    
    return (
        user !== null ? <Outlet /> : <Navigate to="/login" />
    )
}

export default ProtectedRoute