import { useState } from 'react'
import { ModalPreview } from 'components';
import { accessFileRequest } from 'https/requests';
import { PaperClipIcon } from "@heroicons/react/24/solid";

function FileItem({ file, phone, accessToken }) {

    const [imageSrc, setImageSrc] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false)
    }

    const handleRequest = async() => {

        let url = `/auth/client/getFile`;
        let headers = {
            "Authorization" : `${accessToken}`,
            "Content-Type": "application/json"
        }
        let params = {
            phone : phone,
            name : file
        }

        let res = await accessFileRequest(url, headers, params);

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        const extension = fileName.split('.')[1];
        const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
        if(fileExtensions.includes(extension)){
            link.click();
        } else {
            setImageSrc(fileUrl);
            setIsOpen(!isOpen);
        }
    }

    return (
        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
            <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                <span className="ml-2 w-0 flex-1 truncate">
                    {file}
                </span>
            </div>
            <div className="ml-4 flex-shrink-0">
                <span className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer inline-flex items-center" onClick={handleRequest}>
                    Preview
                </span>
            </div>
            <div className='hidden'>
                <ModalPreview 
                    isOpen={isOpen}
                    closeModal={closeModal}
                    imageUrl={imageSrc}
                />
            </div>
        </li>
    )
}

export default FileItem