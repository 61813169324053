import React from 'react';
import { Topbar, Aside, Main, PageHeader, ListLoans, Loader } from 'components';
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Link } from 'react-router-dom';
import { useUser, useLoans } from 'hooks';
import { validateGhanaCard } from 'components/misc/functions';

function Index() {
    const { user } = useUser();
    const { isLoading, loans } = useLoans();
    
    if(isLoading){
        return <Loader />
    }

    return (
        <main>
            <Topbar />
            
            <div className='pt-20 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    {/* show alert if account not verified or show grid of cards */}
                    {/* {(user?.isVerified && user?.idNumber !== undefined) || (user?.idNumber !== undefined && user?.idNumber !== "") ? ( */}
                    {(user?.isVerified && validateGhanaCard(user?.idNumber)) ? (
                        <>
                        <PageHeader title='Dashboard' size="text-2xl" />

                        <ListLoans loans={loans} />
                        </>
                    ) : (
                        <div className='display_card'>
                            <div className='bg-red-100 text-red-700 px-4 py-3 rounded relative flex flex-row items-center' role='alert'>
                                <ExclamationTriangleIcon 
                                    className='h-5 w-5 text-red-700 mr-2'
                                />
                                <span className='block sm:inline'>
                                    <strong className='font-bold'>Account not verified!</strong> Please verify your account with <span className='font-bold'>Next of Kin Information</span> and <span className='font-bold'>GhanaCard Information</span> to continue. <Link to="/profile/edit" className='text-light-blue'>Go to Profile</Link>
                                </span>
                            </div>
                        </div>
                    )}
                    {/* show alert if account not verified or show grid of cards */}
                </Main>
            </div>
        </main>
    )
}

export default Index