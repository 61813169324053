import React from 'react'
import { Footer } from "components";

function Main({ children }) {
    return (
        <div id='main-content' aria-label='Main Content' className='lg:ml-64 overflow-y-auto w-full h-full relative'>
            <div className='py-2 px-1.5 md:py-2 md:px-3 lg:py-2 lg:px-3'>
                {children}
                
                <Footer />
            </div>

        </div>
    )
}

export default Main