import { roundNum } from "../constants";
import { workYears } from "../loanConstants";

// dti score
const scoringDTIStatus = {
    below35 : 30,
    upTo43 : 20,
    upTo50 : 15,
    above51 : 5
}
const scoringDTIDenom = Math.max(...Object.values(scoringDTIStatus));
const scoringDTIMaxWgt = 20;

// income score
const incomeFlow = {
    above10k : 40,
    max10k : 30,
    max5k : 20,
    below2k : 10
}
const incomeFlowMax = Math.max(...Object.values(incomeFlow));
const incomeFlowMaxWgt = 20;

// savings balance
const savingsBalObj = {
    above15k : 25,
    max15k : 20,
    max10k : 15,
    max5k : 10,
    below3k : 5
}
const savingsBalDenom = Math.max(...Object.values(savingsBalObj));
const savingsBalMaxWgt = 15;

// years with current employer
const workYearsDenom = Math.max(...Object.values(workYears))
const workYearsMaxWgt = 15;

// automated payments scoring
const automatedPaymentsObj = {
    Yes : 30,
    No : 0
}
const automatedDenom = Math.max(...Object.values(automatedPaymentsObj));
const automatedPaymentsMaxWgt = 10;

// recent 3 months loan and bills payment history
const loanPaymentHstObj = {
    Yes : 30,
    No : 0
}
const loanPaymentHstMax = Math.max(...Object.values(loanPaymentHstObj));
const loanPaymentHstMaxWgt = 5;
const billPaymentHstMaxWgt = 5;

// credit status of applicable borrowing or payment history
const creditStatusAppHst = {
    noMiss : 30,
    max1 : 20,
    max2 : 5,
    missedMore : 0,
    noCred : 0
}
const creditStatusAppHstMax = Math.max(...Object.values(creditStatusAppHst));
const creditStatusAppHstMaxWght = 5;
const creditStatusbillPaymentMaxWght = 5;


// employer achieve section

// employer MOU
const employerMOUObj = {
    Yes : 30,
    No : 0
}
const employerMOUMax = Math.max(...Object.values(employerMOUObj));
const employerMOUMaxWgt = 7;

// income
const achieveIncomeFlowMaxWgt = 25

// credit status loan payment
const achieveCreditStatusLoans = 7;
// credit status bill payment
const achieveCreditStatusBills = 6;


async function getPayrollScore(type, scoringDTI, averageCashFlow, momoBalance, yearsAtWork, automatedPaymentsStatus, loanPaymentHst, paymentHstWCedisPay, billsPaymentHst, billsHistoryStatus){

    let score = type === 'CedisPay Customer' ? 
            await getEmployerScore(scoringDTI, averageCashFlow, momoBalance, yearsAtWork, automatedPaymentsStatus, loanPaymentHst, paymentHstWCedisPay, billsPaymentHst, billsHistoryStatus) : 
            await getAchieveScore(scoringDTI, averageCashFlow, momoBalance, yearsAtWork, automatedPaymentsStatus, loanPaymentHst, paymentHstWCedisPay, billsPaymentHst, billsHistoryStatus);

    return { 
        score
    };

}

async function getEmployerScore(scoringDTI, averageCashFlow, momoBalance, yearsAtWork, automatedPaymentsStatus, loanPaymentHst, paymentHstWCedisPay, billsPaymentHst, billsHistoryStatus) {

    // 1. scoring DTI
    let scoringDTIRaw = scoringDTI <= 35 ? scoringDTIStatus.below35 : 
                        scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIStatus.upTo43 : 
                        scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIStatus.upTo50 : 
                        scoringDTIStatus.above51;
    let scoringDTIScore = (scoringDTIRaw / scoringDTIDenom) * scoringDTIMaxWgt;
    scoringDTIScore = roundNum(scoringDTIScore, 2);
    console.log("Debt to income ratio: ", scoringDTIScore);

    // 2. income 
    let incomeScore = (averageCashFlow / incomeFlowMax) * incomeFlowMaxWgt;
    incomeScore = roundNum(incomeScore, 2);
    console.log("Income:", incomeScore);

    // 3. savings balance 
    let savingsBalRaw = savingsBalObj[momoBalance];
    let savingsBalScore = (savingsBalRaw / savingsBalDenom) * savingsBalMaxWgt;
    savingsBalScore = roundNum(savingsBalScore, 2);
    console.log("Savings Balance:", savingsBalScore);

    // 4. years with current employer
    let yearsAtWorkRaw = yearsAtWork;
    let yearsAtWorkScore = (yearsAtWorkRaw/workYearsDenom) * workYearsMaxWgt;
    yearsAtWorkScore = roundNum(yearsAtWorkScore, 2);
    console.log("Years at work:", yearsAtWorkScore);

    // 5. automated payments 
    let automatedRaw = automatedPaymentsObj[automatedPaymentsStatus];
    let automatedScore = (automatedRaw / automatedDenom) * automatedPaymentsMaxWgt;
    automatedScore = roundNum(automatedScore, 2);
    console.log("Automated payments: ", automatedScore);

    // 6. recent 3 months loan payment history
    let loanPaymentHstRaw = loanPaymentHstObj[loanPaymentHst];
    let loanPaymentHstScore = (loanPaymentHstRaw / loanPaymentHstMax) * loanPaymentHstMaxWgt;
    loanPaymentHstScore = roundNum(loanPaymentHstScore, 2);
    console.log("Recent 3 months loan payment history:", loanPaymentHstScore);

    // 7. credit status of applicable loan payment history
    let creditStatusloanPaymentRaw = creditStatusAppHst[paymentHstWCedisPay];
    let creditStatusloanPaymentScore = (creditStatusloanPaymentRaw / creditStatusAppHstMax) * creditStatusAppHstMaxWght;
    creditStatusloanPaymentScore = roundNum(creditStatusloanPaymentScore, 2);
    console.log("Credit Status of applicable loan payment history:", creditStatusloanPaymentScore);
    
    // 8. 3 months bills payment history
    let billsPaymentHstRaw = loanPaymentHstObj[billsPaymentHst];
    let billsPaymentHstScore = (billsPaymentHstRaw / loanPaymentHstMax) * billPaymentHstMaxWgt;
    billsPaymentHstScore = roundNum(billsPaymentHstScore, 2);
    console.log("Recent 3 months bills payment history:", billsPaymentHstScore);

    // 9. credit status of applicable bills payment history
    let creditStatusbillPaymentRaw = creditStatusAppHst[billsHistoryStatus];
    let creditStatusbillPaymentScore = (creditStatusbillPaymentRaw / creditStatusAppHstMax) * creditStatusbillPaymentMaxWght;
    creditStatusbillPaymentScore = roundNum(creditStatusbillPaymentScore, 2);
    console.log("Credit Status of applicable bills payment history:", creditStatusbillPaymentScore);

    let totalScore = scoringDTIScore + incomeScore + savingsBalScore + yearsAtWorkScore + automatedScore + loanPaymentHstScore + creditStatusloanPaymentScore + billsPaymentHstScore + creditStatusbillPaymentScore;

    totalScore = roundNum(totalScore, 1);

    return totalScore;

}

async function getAchieveScore(scoringDTI, averageCashFlow, employerMOU, yearsAtWork, automatedPaymentsStatus, loanPaymentHst, paymentHstWCedisPay, billsPaymentHst, billsHistoryStatus) {


    // 1. scoring DTI
    let scoringDTIRaw = scoringDTI <= 35 ? scoringDTIStatus.below35 : 
                        scoringDTI >= 36 && scoringDTI <= 43 ? scoringDTIStatus.upTo43 : 
                        scoringDTI >= 44 && scoringDTI <= 50 ? scoringDTIStatus.upTo50 : 
                        scoringDTIStatus.above51;
    let scoringDTIScore = (scoringDTIRaw / scoringDTIDenom) * scoringDTIMaxWgt;
    scoringDTIScore = roundNum(scoringDTIScore, 2);
    console.log("Debt to income ratio: ", scoringDTIScore);

    // 2. income 
    let incomeScore = (averageCashFlow / incomeFlowMax) * achieveIncomeFlowMaxWgt;
    incomeScore = roundNum(incomeScore, 2);
    console.log("Income:", incomeScore);

    // 3. employer mou 
    let employerMOURaw = employerMOUObj[employerMOU];
    let employerMOUScore = (employerMOURaw / employerMOUMax) * employerMOUMaxWgt;
    employerMOUScore = roundNum(employerMOUScore, 2);
    console.log("Employer signed memorandum:", employerMOU);
    console.log("Employer signed memorandum:", employerMOUScore);

    // 4. years with current employer
    let yearsAtWorkRaw = yearsAtWork;
    let yearsAtWorkScore = (yearsAtWorkRaw/workYearsDenom) * workYearsMaxWgt;
    yearsAtWorkScore = roundNum(yearsAtWorkScore, 2);
    console.log("Years at work:", yearsAtWorkScore);

    // 5. automated payments 
    let automatedRaw = automatedPaymentsObj[automatedPaymentsStatus];
    let automatedScore = (automatedRaw / automatedDenom) * automatedPaymentsMaxWgt;
    automatedScore = roundNum(automatedScore, 2);
    console.log("Automated payments: ", automatedScore);

    // 6. recent 3 months loan payment history
    let loanPaymentHstRaw = loanPaymentHstObj[loanPaymentHst];
    let loanPaymentHstScore = (loanPaymentHstRaw / loanPaymentHstMax) * loanPaymentHstMaxWgt;
    loanPaymentHstScore = roundNum(loanPaymentHstScore, 2);
    console.log("Recent 3 months loan payment history:", loanPaymentHstScore);

    // 7. credit status of applicable loan payment history
    let creditStatusloanPaymentRaw = creditStatusAppHst[paymentHstWCedisPay];
    let creditStatusloanPaymentScore = (creditStatusloanPaymentRaw / creditStatusAppHstMax) * achieveCreditStatusLoans;
    creditStatusloanPaymentScore = roundNum(creditStatusloanPaymentScore, 2);
    console.log("Credit Status of applicable loan payment history:", creditStatusloanPaymentScore);
    
    // 8. 3 months bills payment history
    let billsPaymentHstRaw = loanPaymentHstObj[billsPaymentHst];
    let billsPaymentHstScore = (billsPaymentHstRaw / loanPaymentHstMax) * billPaymentHstMaxWgt;
    billsPaymentHstScore = roundNum(billsPaymentHstScore, 2);
    console.log("Recent 3 months bills payment history:", billsPaymentHstScore);

    // 9. credit status of applicable bills payment history
    let creditStatusbillPaymentRaw = creditStatusAppHst[billsHistoryStatus];
    let creditStatusbillPaymentScore = (creditStatusbillPaymentRaw / creditStatusAppHstMax) * achieveCreditStatusBills;
    creditStatusbillPaymentScore = roundNum(creditStatusbillPaymentScore, 2);
    console.log("Credit Status of applicable bills payment history:", creditStatusbillPaymentScore);

    let totalScore = scoringDTIScore + incomeScore + employerMOUScore + yearsAtWorkScore + automatedScore + loanPaymentHstScore + creditStatusloanPaymentScore + billsPaymentHstScore + creditStatusbillPaymentScore;

    totalScore = roundNum(totalScore, 1);

    return totalScore;

}

export {
    getPayrollScore
}