import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useState, useEffect } from 'react'

function PasswordChecker({ password, strength, setStrength }) {

    // const [signal, setSignal] = useState("");
    const [upperCase, setUpperCase] = useState(true);
    const [lowerCase, setLowerCase] = useState(true);
    const [number, setNumber] = useState(true);
    const [specialChar, setSpecialChar] = useState(true);
    const [length, setLength] = useState(true);

    useEffect(() => {

        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})");
        const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        const analyzePassword = (password, setStrength) => {
            if(strongRegex.test(password)){
                setStrength("strong");
            } else if(mediumRegex.test(password)){
                setStrength("medium");
            } else {
                setStrength("weak");
            }

            if (/[a-z]/.test(password)) {
                setLowerCase(false);
            } else {
                setLowerCase(true);
            }
            if (/[A-Z]/.test(password)) {
                setUpperCase(false);
            } else {
                setUpperCase(true);
            }
            if (/[0-9]/.test(password)) {
                setNumber(false);
            } else {
                setNumber(true);
            }
            // eslint-disable-next-line
            if (/[!@#\$%\^&\*]/.test(password)) {
                setSpecialChar(false);
            } else {
                setSpecialChar(true);
            }
            if (password.length >= 8) {
                setLength(false);
            } else {
                setLength(true);
            }
        }

        analyzePassword(password, setStrength);
    }, [password, setStrength, setLowerCase, setUpperCase, setNumber, setSpecialChar, setLength])

    return (
        <div className='flex flex-col space-y-4'>
            <small className="password-strength flex flex-row">
                {strength === "weak" && (
                    <>
                    <div className="password-line password-weak"></div>
                    <div className="password-line password-transparent"></div>
                    <div className="password-line password-transparent"></div>
                    </>
                )}
                {strength === "medium" && (
                    <>
                    <div className="password-line password-medium"></div>
                    <div className="password-line password-medium"></div>
                    <div className="password-line password-transparent"></div>
                    </>
                )}
                {strength === "strong" && (
                    <>
                    <div className="password-line password-strong"></div>
                    <div className="password-line password-strong"></div>
                    <div className="password-line password-strong"></div>
                    </>
                )}
            </small>

            <div className='text-xs'>
                Password must contain:
                <ul className='list-none list-inside mt-2 space-y-1'>
                    <li className={lowerCase ? 'text-red-500' : 'text-green-500'}>
                        {lowerCase ? 
                            <XCircleIcon className='h-4 w-4 inline-block mr-1' /> : 
                            <CheckCircleIcon className='h-4 w-4 inline-block mr-1' />
                        }
                        At least 1 lower case letter
                    </li>
                    <li className={upperCase ? 'text-red-500' : 'text-green-500'}>
                        {upperCase ?
                            <XCircleIcon className='h-4 w-4 inline-block mr-1' /> :
                            <CheckCircleIcon className='h-4 w-4 inline-block mr-1' />
                        }
                        At least 1 upper case letter
                    </li>
                    <li className={number ? 'text-red-500' : 'text-green-500'}>
                        {number ? 
                            <XCircleIcon className='h-4 w-4 inline-block mr-1' /> : 
                            <CheckCircleIcon className='h-4 w-4 inline-block mr-1' />
                        }
                        At least 1 number
                    </li>
                    <li className={specialChar ? 'text-red-500' : 'text-green-500'}>
                        {specialChar ? 
                            <XCircleIcon className='h-4 w-4 inline-block mr-1' /> : 
                            <CheckCircleIcon className='h-4 w-4 inline-block mr-1' />
                        }
                        At least 1 special character
                    </li>
                    <li className={length ? 'text-red-500' : 'text-green-500'}>
                        {length ? 
                            <XCircleIcon className='h-4 w-4 inline-block mr-1' /> : 
                            <CheckCircleIcon className='h-4 w-4 inline-block mr-1' />
                        }
                        At least 8 characters
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PasswordChecker