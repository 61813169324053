import { dateToHumanFormat, numberFormatter } from 'components/misc/constants'
import { extractDate } from 'components/misc/functions'
import { Link } from "react-router-dom"

function LItem({ item }) {

    const loanState = item?.approvalStage;
    let loanProcess = item?.approvalStage === "pendingDisbursement" ? "pending disbursement" : 
                        item?.approvalStage === "pendingFacilityLetter" ? "attach facility letter" : 
                        item?.approvalStage === "completed" ? "Completed" : 
                        item?.approvalStage === "active" ? "Active" : 
                        item?.state === "rejected" ? "Rejected" : 
                        "In Review";
    const loanClass = loanState === "active" ? `bg-emerald-100 text-emerald-700` :
                        loanState === "completed" ? `bg-sky-100 text-sky-700` : 
                        item?.state === "rejected" ? `bg-red-100 text-red-700` : 
                        `bg-slate-100 text-slate-700`

    return (
        <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-semibold tracking-wide text-gray-500 dark:text-slate-300">
            <td className='px-4 py-3 capitalize'>
                {item.type}
            </td>
            <td className='px-4 py-3'>
                {numberFormatter(item.amount)}
            </td>
            <td className='px-4 py-3'>
                {dateToHumanFormat(extractDate(item.date))}
            </td>
            <td className='px-4 py-3'>
                <span className={`rounded-full px-2.5 py-1 text-xs ${loanClass} uppercase`}>
                    {loanProcess}
                </span>
            </td>
            <td className='px-4 py-3'>
                <Link to={`/preview/${item?.state}/${item?.type.toLowerCase()}/${item?._id}`}>
                    <span className="text-gold-700 hover:text-gold-500 dark:text-slate-300">View</span>
                </Link>
            </td>
        </tr>
    )
}

export default LItem