import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline"

function Pagination({ nPages, currentPage, setCurrentPage, length }) {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const goToNextPage = () => {
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }

    const goToPrevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    return (
        <div className="flex items-center justify-between -mx-4 sm:-mx-8 px-4 py-2 sm:px-8">
            {/* mobile pagination */}
            <div className="flex flex-1 justify-between sm:hidden mx-4">
                <span className={`relative inline-flex items-center rounded-md border dark:border-white/[.2] px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${currentPage === 1 ? 'cursor-not-allowed hover:bg-transparent text-gray-400' : 'cursor-pointer text-gray-40'}`} onClick={goToPrevPage} disabled={currentPage === 1 && true}>
                    Previous
                </span>
                <span className={`relative ml-3 inline-flex items-center rounded-md border dark:border-white/[.2] px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${currentPage === nPages ? 'cursor-not-allowed hover:bg-transparent text-gray-400' : 'cursor-pointer text-gray-40'}`} onClick={goToNextPage} disabled={pageNumbers[pageNumbers.length - 1] && true}>
                    Next
                </span>
            </div>
            {/* mobile pagination */}

            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between px-4">

                <div>
                    {nPages > 1 ? 
                    <p className="text-sm text-gray-700 dark:text-text-dark">
                        Showing <span className="font-medium">{currentPage > 1 ? currentPage * 10 - 10 + 1 : 1}</span> to <span className="font-medium">{(currentPage > 1 && (currentPage * 10 > length)) ? length : currentPage > 1 ? currentPage * 10 : 10}</span> of{' '}
                        <span className="font-medium">{length}</span> results
                    </p>
                    : 
                    <p className="text-sm text-gray-700 dark:text-text-dark">
                        Showing <span className="font-medium">{length}</span> result{length > 1 ? `s` : ``}
                    </p>
                    }
                </div>

                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <span className={`relative inline-flex items-center rounded-l-md px-4 py-2 hover:bg-gray-50 dark:bg-transparent border dark:border-white/[.2] ${currentPage === 1 ? 'cursor-not-allowed hover:bg-transparent text-gray-400' : 'cursor-pointer text-gray-40'}`} onClick={goToPrevPage} disabled={currentPage === 1 && true}>
                            <span className="sr-only">Previous</span>
                            <ArrowLongLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" /> 
                            <span className='text-sm'>Previous</span>
                        </span>

                        {pageNumbers?.map((item) => (
                            <span key={item} aria-current="page" className={
                                `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === item ? 
                                    "z-10 bg-gold-700 dark:bg-transparent border dark:border-white/[.2] text-white dark:text-text-dark" : 
                                    "text-gray-900 hover:bg-gray-50 hover:text-slate-700 border dark:border-white/[.2]"}`} onClick={() => setCurrentPage(item)}>
                                {item}
                            </span>
                        ))}

                        <span className={`relative inline-flex items-center rounded-r-md px-4 py-2 hover:bg-gray-50 dark:bg-transparent border dark:border-white/[.2] ${currentPage === nPages ? 'cursor-not-allowed hover:bg-transparent text-gray-400' : 'cursor-pointer text-gray-40'}`} onClick={goToNextPage} disabled={pageNumbers[pageNumbers.length - 1] && true}>
                            <span className="sr-only">Next</span>
                            <ArrowLongRightIcon className="h-5 w-5 mr-2" aria-hidden="true" /> 
                            <span className='text-sm'>Next</span>
                        </span>
                    </nav>
                </div>

            </div>
        </div>
    )
}

export default Pagination