import axios from "axios";

const baseUrl = process.env.REACT_APP_STAGING_API;

const postRequest = async(url, data) => {
    let requestOptions = {
        method : "POST",
        withCredentials : false,
        headers : {
            "Content-Type" : "application/json"
        },
        data
    }

    try {
        
        let res = await axios(baseUrl + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res.data;
        }

    } catch (error) {
        if(error){
            return error.response.data
        }
    }
}

const getRequest = async(url, accessToken, idToken, data) => {
    
    let requestOptions = {
        method : "GET",
        withCredentials : true,
        maxBodyLength: Infinity,
        headers : {
            "Authorization" : `${accessToken}`,
            "client-token" : `${idToken}`,
        },
        params : data
    }

    try {
        
        let res = await axios(baseUrl + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res.data;
        }

    } catch (error) {
        if(error){
            return error.response.data
        }
    }
}


// modify this as needed
const fetchRequests = async(url, method, headers, params, data) => {
    let options = {
        method : method,
        withCredentials : true,
        headers,
        params,
        data
    }

    try {

        let res = await axios(baseUrl + url, options);
        return res.data;
        
    } catch (error) {
        if(error){
            return error.response.data
        }
    }
}


const accessFileRequest = async(url, headers, params) => {
    let requestOptions = {
        method : "GET",
        withCredentials : false,
        headers,
        params,
        responseType : "blob"
    }

    try {
        
        let res = await axios(baseUrl + url, requestOptions);

        if(res.status === 400 || res.status === 500){
            console.log(`Error is: ${res.data}`)
        } else {
            // console.log(res.data)
            return res;
        }

    } catch (error) {
        if(error){
            return error.response.data
        }
    }
}

export {
    postRequest,
    getRequest,
    fetchRequests,
    accessFileRequest
}