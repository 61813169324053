import { useState, useEffect } from 'react';
import { Topbar, Aside, Main, ApplicationDetails, Documents, PaymentDetails, Loader, Repayment, Error } from 'components';
import { useUser } from 'hooks';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { show } from 'providers/toastSlice';
import { fetchRequests } from 'https/requests';
import { Tab } from '@headlessui/react'

function Index() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { accessToken } = useUser();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const state = path[2];
    const loanType = path[3];
    const loanId = path[4];

    const [isLoading, setIsLoading] = useState(true);
    const [loan, setLoan] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        const fetchLoans = async () => {
            setIsLoading(true);

            const url = `${process.env.REACT_APP_STAGING_LOAN}/preview`;

            const headers = {
                "Authorization" : `${accessToken}`,
                "Content-Type": "application/json"
            }

            const params = {
                loanId : loanId,
                loanType : loanType,
                state : state
            }

            try {

                let res = await fetchRequests(url, "get", headers, params, {});

                if(res?.code === 401){
                    navigate("/login");
                    dispatch(show({
                        state : true,
                        message : `Your session has expired, please login to continue.`,
                        type : "token"
                    }))
                    return;
                }

                if(res?.err){
                    setErrMsg(res?.err);
                    dispatch(show({
                        state : true,
                        message : `${res?.err}`,
                        type : "error"
                    }))
                    return;
                }

                setLoan(res)

            } catch (error) {
                dispatch(show({
                    state : true,
                    message : `${error}`,
                    type : "error"
                }))
            } finally {
                setIsLoading(false);
            }
        }
        
        fetchLoans();

    }, [dispatch, navigate, loanType, loanId, state, accessToken])

    if(isLoading){
        return (
            <Loader />
        )
    }

    if(!loan){
        return (
            <Error code={"500"} message={errMsg} />
        )
    }

    return (
        <main>
            <Topbar />
            
            <div className='pt-20 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>

                    <div className='display_card'>

                        <h3 className='text-3xl font-bold mb-6 inline-flex items-center w-full'>
                            {loan?.type} application: <span className={`mt-1 ml-2 px-3 py-1.5 rounded-md text-xs capitalize ${loan?.state === "active" ? 'bg-emerald-100 text-emerald-700' : 
                                loan?.state === "rejected" ? 'bg-red-100 text-red-700' :
                                loan?.state === "completed" ? 'bg-sky-100 text-sky-700' :
                                'bg-slate-100 text-slate-700'}`
                            }>{loan?.state}</span>
                        </h3>

                        {/* show this when loan is in active state */}
                        {loan?.state === "active" && (
                            <Repayment loan={loan} />
                        )}
                        {/* show this when loan is in active state */}

                        <Tab.Group>
                            <Tab.List className={`inline-flex px-2 py-2 rounded-md space-x-1 p-1 ${loan?.state === 'active' && 'mt-10'} bg-gray-100 dark:bg-dark-page`}>
                                <Tab className={({ selected }) => classNames('px-6 py-2', selected ? 'bg-white dark:bg-dark-bg dark:text-slate-200 rounded-md' : 'hover:bg-white dark:hover:bg-dark-bg dark:hover:text-slate-200 hover:rounded-md')}>
                                    Application Details
                                </Tab>
                                <Tab className={({ selected }) => classNames('px-6 py-2', selected ? 'bg-white dark:bg-dark-bg dark:text-slate-200 rounded-md' : 'hover:bg-white dark:hover:bg-dark-bg dark:hover:text-slate-200 hover:rounded-md')}>
                                    Loan Documents
                                </Tab>
                                <Tab className={({ selected }) => classNames('px-6 py-2', selected ? 'bg-white dark:bg-dark-bg dark:text-slate-200 rounded-md' : 'hover:bg-white dark:hover:bg-dark-bg dark:hover:text-slate-200 hover:rounded-md')}>
                                    Payment History
                                </Tab>
                            </Tab.List>

                            <Tab.Panels className="mt-2">
                                <Tab.Panel className={classNames('rounded-xl bg-white dark:bg-transparent p-3')}>
                                    <ApplicationDetails loan={loan} />
                                </Tab.Panel>

                                <Tab.Panel className={classNames('rounded-xl bg-white dark:bg-transparent p-3')}>
                                    <Documents loan={loan} />
                                </Tab.Panel>

                                <Tab.Panel className={classNames('rounded-xl bg-white dark:bg-transparent p-3')}>
                                    <PaymentDetails loan={loan} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>

                </Main>

            </div>
        </main>
    )
}

export default Index