import { useState } from 'react'
import Logo from "../../assets/images/logo.png"
import banner from "../../assets/images/banner.jpeg";
import { FormInput, Button, OTPInput } from "components";
import { validateLogin } from 'components/misc/functions';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { Link, useNavigate } from 'react-router-dom';
import { postRequest } from 'https/requests';
import { login } from 'providers/userSlice';

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [resendProcess, setResendProcess] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        // Validate form inputs
        const errors = validateLogin(phone, password);
        for (const [key, value] of Object.entries(errors)) {
            setProcessing(false);
            console.log(`${key} error`)
            dispatch(show({
                state : true,
                message : `${value}`,
                type : "error"
            }))
            return;
        }

        let data = {
            phone,
            password
        }

        let res = await postRequest(`${process.env.REACT_APP_STAGING_AUTH}/login`, data);
        setProcessing(false);
        
        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        if(res?.message){
            dispatch(show({
                state : true,
                message : `${res?.message}`,
                type : "success"
            }))

            setStepOne(false);
            setStepTwo(true);
        }

    }

    const handleOTPCode = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let data = {
            phone,
            code
        }

        let res = await postRequest(`${process.env.REACT_APP_STAGING_AUTH}/verify`, data);
        setProcessing(false);
        
        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        dispatch(show({
            state : true,
            message : `Welcome!`,
            type : "success"
        }));

        dispatch(login(res));
        setTimeout(() => {
            navigate("/");
        }, 2000);
    }

    const handleResendRequest = async(e) => {
        e.preventDefault();
        setResendProcess(true);

        let data = {
            phone
        }

        let res = await postRequest(`${process.env.REACT_APP_STAGING_AUTH}/resend`, data);
        setResendProcess(false);
        
        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        dispatch(show({
            state : true,
            message : `${res?.message}`,
            type : "success"
        }));
    }

    return (
        <div className='min-h-[100vh] min-w-[100vw] flex bg-gray-50 dark:bg-dark-bg relative'>

            <div className='w-full lg:w-1/2 flex flex-col items-center justify-center'>
                <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mb-6' />

                <div className='w-[90%] md:w-[60%] lg:w-[70%] shadow-sm rounded-lg h-auto p-6 space-y-6 bg-white dark:bg-dark-bg dark:border dark:border-slate-700'>
                    <h4 className='dark:text-slate-200 text-display font-medium text-3xl text-center'>
                        Login
                    </h4>

                    {stepOne && (
                    <form id="admin-login" onSubmit={handleRequest} className='grid grid-cols-1 gap-6 px-2'>
                        <FormInput 
                            label='Phone Number'
                            inputMode='numeric'
                            pattern='[0-9]*'
                            name='phone'
                            id='phone'
                            placeholder='Enter your phone number'
                            value={phone}
                            setValue={setPhone}
                        />
                        
                        <FormInput 
                            label='Password'
                            type='password'
                            name='password'
                            id='password'
                            value={password}
                            setValue={setPassword}
                        />

                        <Button 
                            btnText={"Submit"}
                            btnType={"submit"}
                            btnClasses={"form-button"}
                            processing={processing}
                        />
                    </form>
                    )}

                    {stepTwo && (
                        <>
                        <form id="admin-login" onSubmit={handleOTPCode} className='grid grid-cols-1 gap-6 px-2'>
                            <div>
                                <label htmlFor="otp-0" className="form-label">Verification Code</label>
                                <OTPInput len={4} setInput={setCode} />
                            </div>

                            <Button 
                                btnText={"Sign In"}
                                btnType={"submit"}
                                btnClasses={"form-button"}
                                processing={processing}
                            />
                        </form>

                        <form id='resend-code' onSubmit={handleResendRequest} className='flex items-center justify-center'>
                            <Button 
                                btnText={"Resend code"}
                                btnType={"submit"}
                                btnClasses={""}
                                processing={resendProcess}
                            />
                        </form>
                        </>
                    )}

                    <p className='text-center'>
                        <Link to="/forgot-password" className='mt-6'>
                            Forgot password
                        </Link>
                    </p>
                </div>

                <Link to="/register" className='mt-6'>
                    Don't have an account? <span className='dark:text-slate-300'>Register</span>
                </Link>
            </div>

            <div className='hidden lg:block w-1/2 relative'>
                {/* <div className='absolute bottom-0 left-0 w-full h-24 bg-black bg-opacity-40'>
                    <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mt-6' />
                    <p className='w-96 mx-auto text-white'>
                        Belong to a place where you are financially supported
                    </p>
                </div> */}

                <img src={banner} alt="logo" className='w-full h-full object-cover' />
            </div>

        </div>
    )
}

export default Login