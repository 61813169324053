import { useState } from 'react';
import { LItem, Pagination } from "components";

function ListLoans({ loans }) {
    
    loans = loans.sort((a,b) => Date.parse(b.date) - Date.parse(a.date));

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = loans?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(loans?.length / recordsPerPage);

    return (
        <div className='display_card'>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden border dark:border-white/[.2]">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 dark:text-white uppercase border-b dark:bg-transparent dark:border-white/[.2]'>
                            <tr>
                                <th scope="col" className='px-4 py-3'>Loan Type</th>
                                <th scope="col" className='px-4 py-3'>Amount</th>
                                <th scope="col" className='px-4 py-3'>Applied on</th>
                                <th scope="col" className='px-4 py-3'>Stage</th>
                                <th scope="col" className='px-4 py-3'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {currentRecords && currentRecords?.length > 0 ? (
                                currentRecords?.map(item => (
                                    <LItem 
                                        key={item?._id}
                                        item={item}
                                    />
                                ))
                            ) : (
                            <tr className="px-4 py-6 border-b dark:border-white/[.2] text-sm font-semibold tracking-wide text-gray-500 dark:text-white">
                                <td colSpan="6" className='px-4 py-3 text-center font-medium'>
                                    No loan applications 
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                    {(currentRecords && currentRecords?.length > 0) && 
                        <Pagination 
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            length={loans?.length}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ListLoans