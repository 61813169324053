import React from 'react'

function PageHeader({ title, size }) {
    return (
        <h2 className={`p-4 ${size} leading-6 font-medium dark:text-slate-200`}>
            {title}
        </h2>
    )
}

export default PageHeader