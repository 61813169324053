import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/css/tailwind.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { store, persistor } from "./providers/store";
import { PersistGate } from "redux-persist/es/integration/react";
import { Toast } from "components";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<HttpsRedirect>
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <Toast />
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </HttpsRedirect>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
