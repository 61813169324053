import { FileItem } from 'components';
import { useUser } from 'hooks';

function Files({ files }) {

    const { accessToken, user } = useUser();
    
    return (
        <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
            <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                {files && files.length > 0 ? (
                    files.map((file, index) => (
                        <FileItem 
                            key={index} 
                            file={file} 
                            phone={user?.phone}
                            accessToken={accessToken}
                        />
                    ))
                ) : (
                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <span className="w-0 flex-1 truncate">
                            No files found
                        </span>
                    </li>
                )}
            </ul>
        </dd>
    )
}

export default Files