import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hide } from "providers/toastSlice";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

function Toast() {

    const dispatch = useDispatch();

    const state = useSelector((state) => state.toast.state);
    const message = useSelector((state) => state.toast.message);
    const type = useSelector((state) => state.toast.type);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        if(state){
            if(type === "token"){
                return;
            } else {
                const intervalId = setInterval(() => {
                    dispatch(hide())
                }, 3000)
    
                return () => clearInterval(intervalId);
            }
        }
    },[state, type, dispatch])

    return (
        <>
        {type === "token" ? (
        <figure className={classNames("notification bg-red-300 transition duration-150 ease-out bottom-12")}>
            <div className="notification__body">
                <div className={classNames("notification__description text-red-700")}>
                    <div className={classNames("icon__wrapper")}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="w-4 h-4 mr-2 text-red-700">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                        </svg>
                    </div>                    
                    {message} 
                </div> 
            </div>
        </figure>
        ) : (
            <figure className={classNames("notification transition duration-150 ease-out", state ? "bottom-12" : "-bottom-12", type === "success" && "bg-emerald-200 dark:bg-emerald-300", type === "error" && "bg-red-200 dark:bg-red-300")}>
            <div className="notification__body">
                <div className={classNames("notification__description", type === "success" && "text-emerald-700", type === "error" && "text-red-700")}>
                    <div className={classNames("icon__wrapper")}>
                        {type === "success" ? (
                            <CheckCircleIcon className="h-5 w-5 mr-2 text-emerald-400" aria-hidden="true" />
                        ) : (
                            <XCircleIcon className="h-5 w-5 mr-2 text-red-400" aria-hidden="true" />
                        )}
                    </div>                    
                    {message} 
                </div> 
            </div>
        </figure>
        )}
        </>
    )
}

export default Toast