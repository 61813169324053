import { useState } from 'react';
import { PaperClipIcon } from "@heroicons/react/24/solid";
import { fileTypesLong } from 'components/misc/loanConstants';
import { useUser } from "hooks";
import { accessFileRequest } from "https/requests";
import { ModalPreview } from 'components';

function File({ item, id }) {

    const fileExt = item.split(".").pop();
    const fileExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];

    const { accessToken } = useUser();
    const [imageSrc, setImageSrc] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false)
    }

    const handleFileRequest = async(e) => {
        e.preventDefault();

        let url = `/loans/client/getFile`;
        let headers = {
            "Authorization" : `${accessToken}`,
            "Content-Type": "application/json"
        }
        let params = {
            loanId : id,
            name : item
        }

        let res = await accessFileRequest(url, headers, params);

        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = res.headers['content-disposition'].split('filename=')[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        const extension = fileName.split('.')[1];
        if(fileExtensions.includes(extension)){
            link.click();
        } else {
            setImageSrc(fileUrl);
            setIsOpen(!isOpen);
        }
    }

    return (
        <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
            <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                    <span className="truncate font-medium">
                        {`${fileTypesLong[item.split("_")[1].split(".")[0]]}.${item.split(".").pop()}`}
                    </span>
                    {/* <span className="flex-shrink-0 text-gray-400">2.4mb</span> */}
                </div>
            </div>
            <div className="ml-4 flex-shrink-0">
                <button type="button" onClick={handleFileRequest} className="font-medium text-gold-700 hover:text-gold-500">
                    {fileExtensions.includes(fileExt) ? 'Download' : 'Preview'}
                </button>
            </div>
            <div className='hidden'>
                <ModalPreview 
                    isOpen={isOpen}
                    closeModal={closeModal}
                    imageUrl={imageSrc}
                />
            </div>
        </li>
    )
}

export default File