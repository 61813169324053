import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function Error({ code, message }) {

    const navigate = useNavigate();

    const handleRequest = () => {
        navigate(-1);
    }

    return (
        <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
			<div className="text-center">
				<p className="text-base font-semibold text-red-600">
                    {code}
                </p>
				<h1 className="mt-4 text-3xl font-bold tracking-tight text-red-600 sm:text-5xl">
					Oops! That's an error.
				</h1>
				<p className="mt-6 text-base leading-7 text-gray-600">
					{message}
				</p>
				<div className="mt-10 flex items-center justify-center gap-x-6">
					<button type="button" onClick={handleRequest} className="rounded-md bg-gold-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gold-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gold-500">
						Go back
					</button>
					<Link to="/contact" className="text-sm font-semibold text-gray-900">
						Contact support <span aria-hidden="true">&rarr;</span>
					</Link>
				</div>
			</div>
		</main>
    )
}

export default Error