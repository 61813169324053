import { Routes, Route } from "react-router-dom";
import { Login, Register, ForgotPassword, Dashboard, Profile, Edit, LoanIndex, Switch, Preview, NotFound } from "./components";
import ProtectedRoute from "./utils/ProtectedRoutes";

function App() {
	return (
		<>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />

                <Route element={<ProtectedRoute />}>
                    <Route path="/" exact element={<Dashboard />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/profile/edit" element={<Edit />} />

                    <Route path="/loans" element={<LoanIndex />} />
                    <Route path="/loans/:path" element={<Switch />} />

                    <Route path="/preview/:state/:type/:loanId" element={<Preview />} />
                </Route>

                {/* error 404 page */}
                <Route path="*" element={<NotFound />} />
                {/* error 404 page */}
            </Routes>
        </>
	);
}

export default App;
