import { useState } from 'react'
import banner from "../../assets/images/banner.jpeg";
import Logo from "../../assets/images/logo.png"
import { FormInput, Button, OTPInput, PasswordChecker } from "components";
import { fetchRequests, postRequest } from 'https/requests';
import { useDispatch } from 'react-redux';
import { show } from 'providers/toastSlice';
import { useNavigate, Link } from 'react-router-dom';

function ForgotPassword() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_pass, setConfirm_pass] = useState("");
    const [stepOne, setStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);
    const [strength, setStrength] = useState(null); 
    const [process, setProcess] = useState(false);
    const [resendProcess, setResendProcess] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let data = {
            phone
        }

        let url = `/auth/client/forgot_password`;
        let res = await postRequest(url, data);
        setProcess(false);

        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        if(res?.message){
            dispatch(show({
                state : true,
                message : `${res?.message}`,
                type : "success"
            }))
            
            setStepOne(false);
            setStepTwo(true);
        }
    }

    const handleNewPassRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(confirm_pass !== password || confirm_pass === "" || password === ""){
            setProcess(false);
            dispatch(show({
                state : true,
                type : "error",
                message : (confirm_pass !== password) ? `Passwords do not match` : `Password fields can not be empty`,
            }))
            return;
        }

        let data = {
            phone,
            code,
            password
        }
        let headers = {
            "Content-Type" : "application/json"
        }

        let url = `/auth/client/reset_password`;
        let res = await fetchRequests(url, "PUT", headers, {}, data);
        setProcess(false);

        if(res?.err){
            dispatch(show({
                state : true,
                type : "error",
                message : `${res?.err}`
            }))
            return;
        }

        if(res?.message){
            dispatch(show({
                state : true,
                message : `${res?.message}`,
                type : "success"
            }))
        }

        setTimeout(() => {
            navigate("/login")
        }, 2000)
    }

    const handleResendRequest = async(e) => {
        e.preventDefault();
        setResendProcess(true);

        let data = {
            phone
        }

        let res = await postRequest(`${process.env.REACT_APP_STAGING_AUTH}/resend`, data);
        setResendProcess(false);
        
        if(res?.err){
            dispatch(show({
                state : true,
                message : `${res?.err}`,
                type : "error"
            }))
            return;
        }

        dispatch(show({
            state : true,
            message : `${res?.message}`,
            type : "success"
        }));
    }

    return (
        <div className='min-h-[100vh] min-w-[100vw] flex bg-gray-50 dark:bg-dark-bg relative'>

            <div className='w-full lg:w-1/2 flex flex-col items-center justify-center'>
                <img src={Logo} alt='Company Logo' className='w-auto h-12 object-cover mx-auto mb-6' />
                
                <div className='w-[90%] md:w-[60%] lg:w-[70%] shadow-sm rounded-lg h-auto p-6 space-y-6 bg-white dark:bg-dark-bg'>
                    <h4 className='dark:text-slate-200 text-display font-medium text-3xl text-center'>
                        Forgot Password
                    </h4>

                    {stepOne && (
                        <form id="admin-login" onSubmit={handleRequest} className='grid grid-cols-1 gap-6 px-2'>
                            <div>
                                <label htmlFor="phone" className='form-label'>Phone Number</label>
                                <input type="text" name="phone" id="phone" className='form-input' autoComplete="phone" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>

                            <div>
                                <Button 
                                    btnText={"Verify"}
                                    btnClasses={"form-button"}
                                    btnType="submit"
                                    processing={process}
                                />
                            </div>
                        </form>
                    )}

                    {stepTwo && (
                        <>
                        <form id="admin-login" onSubmit={handleNewPassRequest} className='grid grid-cols-1 gap-6 px-2'>
                            <div>
                                <label htmlFor="otp-0" className="form-label">Verification Code</label>
                                <OTPInput len={4} setInput={setCode} />
                            </div>

                            <FormInput 
                                label='New Password'
                                type='password'
                                name='password'
                                id='password'
                                value={password}
                                setValue={setPassword}
                            />

                            <FormInput 
                                label='Confirm Password'
                                type='password'
                                name='confirm_pass'
                                id='confirm_pass'
                                value={confirm_pass}
                                setValue={setConfirm_pass}
                            />

                            <div className='col-span-full -mt-1'>
                            {password !== "" && (
                                <PasswordChecker 
                                    password={password}
                                    strength={strength}
                                    setStrength={setStrength}
                                />
                            )}
                            </div>

                            <Button 
                                btnText={"Confirm"}
                                btnClasses={"form-button"}
                                btnType="submit"
                                processing={process}
                            />
                        </form>

                        <form id='resend-code' onSubmit={handleResendRequest} className='flex items-center justify-center'>
                            <Button 
                                btnText={"Resend code"}
                                btnType={"submit"}
                                btnClasses={""}
                                processing={resendProcess}
                            />
                        </form>
                        </>
                    )}

                    <p className='text-center'>
                        <Link to="/login" className='hover:underline'>Back to Login</Link>
                    </p>
                </div>
            </div>

            <div className='hidden lg:block w-1/2'>
                <img src={banner} alt="logo" className='w-full h-full object-cover' />
            </div>
        </div>
    )
}

export default ForgotPassword