import { useSelector } from 'react-redux';
import { getUser } from 'providers/userSlice';

function useUser() {

    const user = useSelector(getUser);

    return {
        user : user.user,
        idToken : user.idToken,
        accessToken : user.accessToken,
        refreshToken : user.refreshToken,
    }
}

export default useUser;