import React from 'react'

function Loader() {
    return (
        <div className='flex justify-center items-center h-screen'>
            <div className='w-12 h-12 border-t-2 border-b-2 border-gold-700 dark:border-gold-500 rounded-full animate-spin' />
        </div>
    )
}

export default Loader