import { useState } from 'react'
import { Button, Photo } from 'components';
import { fetchRequests } from 'https/requests';
import { useUser } from 'hooks';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { show } from 'providers/toastSlice';
import { updateUserInfo } from 'providers/userSlice';
import { maxFileSize, validateGhanaCard } from 'components/misc/functions';

function GCardInfo({ idNumber }) {

	const { accessToken } = useUser();
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const [cardNumber, setCardNumber] = useState(idNumber ? idNumber : '');
    const [frontPhoto, setFrontPhoto] = useState('');
    const [backPhoto, setBackPhoto] = useState('');
    const [selfie, setSelfie] = useState('');
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setProcessing(true);

		let url = `/auth/client/id-verification`;
		let headers = {
            "Authorization" : `${accessToken}`,
            'Content-Type': 'multipart/form-data'
        }

		if(!validateGhanaCard(cardNumber)){
			dispatch(show({
				state : true,
				message : "Invalid GhanaCard Number, must be in the format GHA-123456789-0",
				type : "error"
			}))
			setProcessing(false);
			return;
		}

		if(selfie.size > maxFileSize){
			dispatch(show({
				state : true,
				message : "Selfie file size is too large. Maximum file size is 1MB",
				type : "error"
			}))
			setProcessing(false);
			return;
		}

		const formData = new FormData();
		formData.append('cardNumber', cardNumber);
		formData.append('ghanaCardFrontPhoto', frontPhoto);
		formData.append('ghanaCardBackPhoto', backPhoto);
		formData.append('selfie', selfie);

		let res = await fetchRequests(url, "POST", headers, {}, formData);
		setProcessing(false);

		if(res?.code === 401){
			navigate("/login");
			dispatch(show({
				state : true,
				message : `Your session has expired, please login to continue.`,
				type : "token"
			}))
			return;
		}
		if(res?.err){
			dispatch(show({
				state : true,
				message : `${res?.err}`,
				type : "error"
			}))
			return;
		}

		dispatch(show({
			state : true,
			message : `${res?.message}`,
			type : "success"
		}));

        dispatch(updateUserInfo(res?.data?.client));

		setTimeout(() => {
			navigate('/profile');
		}, 200);

    }

    return (
		<form className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full space-y-6" onSubmit={handleSubmit}>
			<div className="col-span-2">
				<label htmlFor="ghanaCard" className="form-label">
					GhanaCard Number
				</label>
				<input
					type="text"
					name="ghanaCard"
					pattern={"GHA-\\d{9}-\\d"}
					id="ghanaCard"
					className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
					placeholder="Enter your GhanaCard Number"
					value={cardNumber}
					onChange={(e) => setCardNumber(e.target.value)}
				/>
			</div>

			<div className='col-span-2'>
				<p className="px-3 py-1.5 bg-slate-100 text-slate-700 text-center text-base mt-3 rounded-md w-full">For better maneuverability of the camera, kindly use your phone for this section. Thank you!</p>
			</div>

			{/* Front photo */}
			<div className="col-span-2">
			<Photo 
				label={"Front photo of GhanaCard"}
				name={"front-photo"}
				value={frontPhoto}
				setValue={setFrontPhoto}
			/>
			</div>
			{/* Front photo */}

			{/* Back photo */}
			<div className="col-span-2">
			<Photo 
				label={"Back photo of GhanaCard"}
				name={"back-photo"}
				value={backPhoto}
				setValue={setBackPhoto}
			/>
			</div>
			{/* Back photo */}

			{/* Selfie photo */}
			<Photo 
				label={"Selfie of you"}
				name={"selfie-photo"}
				value={selfie}
				setValue={setSelfie}
			/>
			{/* Selfie photo */}

            <div className='col-span-2 md:col-span-1 mt-6 inline-flex gap-3'>
                <Button 
                    btnText={"Upload"}
                    btnType={"submit"}
                    btnClasses={"form-button"}
                    processing={processing}
					disabled={cardNumber !== "" && frontPhoto !== "" && backPhoto !== "" && selfie !== "" ? false : true}
                />

				<Button 
                    btnText={"Cancel"}
                    btnType={"button"}
                    btnClasses={"w-full px-3 py-1.5 bg-slate-100 rounded-md"}
                    btnFunction={() => navigate("/profile", { replace : true })}
                />
            </div>
		</form>
	);
}

export default GCardInfo