import { Link } from 'react-router-dom'

function Footer() {

    const year = new Date().getFullYear();

    return (
        <>
            <footer className='display_card flex items-center justify-between'>
                <ul className='flex flex-col items-start md:flex-row md:items-center md:space-x-6 space-y-6 md:space-y-0'>
                    <li>
                        <Link to="/terms">
                            Terms &amp; Conditions
                        </Link>
                    </li>
                    <li>
                        <Link to="/privacy-policy">
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/cookie-policy">
                            Cookie Policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            Contact
                        </Link>
                    </li>
                </ul>

                <div>
                    {/* <Link to="/">

                    </Link> */}
                </div>
            </footer>

            <p className='text-center my-10 mx-10 md:mx-0'>
                &copy; 2019 - {year} CedisPay Micro-Finance Limited. All Rights Reserved.
            </p>
        </>
    )
}

export default Footer