import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function ModalPreview({ isOpen, closeModal, imageUrl }) {
	
    return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-30" onClose={closeModal}>
				<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black/25" />
				</Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-2 text-center">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
							<Dialog.Panel className="transform overflow-hidden rounded-lg bg-white dark:bg-dark-bg p-3 text-left align-middle shadow-xl transition-all max-h-[90vh] max-w-[90vw]">
                                <img src={imageUrl} alt="default" className='max-h-[75vh] w-auto object-contain object-center' /> 
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
}

export default ModalPreview