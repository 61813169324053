import { workYears, incomeScores, loanAmtLimits, loanProducts, creditScoreThresholds, capitalMeMomoAverage, capitalMeIncomeRange, interestRate, yearsInBusiness, cashMeIncomeFlow } from "./loanConstants";
import { roundNum } from "./constants";
import { getLoanMeAmountEligible, getCustomerRate, getPensionCreditEligibility, getPensionSecuredEligibility, getPenCashEligibility, getCapitalMeCreditEligibility, getCapitalMeAmountEligibility, getCedisCreditEligibility, getCashMeEligibility, getInvestmentEligibility, getPayrollEligibility } from "./eligibleCalc";
import { getLoanMePaymentValues, getPensionPaymentValues, getCapitalMePaymentValues, getCreditPaymentValues, getCashMePaymentValues, getInvestmentPaymentValues, getPayrollPaymentValues } from "./paymentValues";
import { getLoanMeScore } from "./scores/score-loanme";
import { getPensionScore } from "./scores/score-pension";
import { getCapitalMeScore } from "./scores/score-business";
import { getCedisCreditScore } from "./scores/score-credit";
import { getCashMeScore } from "./scores/score-cashme";
import { getInvestmentScore } from "./scores/score-investment";
import { getPayrollScore } from "./scores/score-payroll";

async function preCheckLoanEligibility(loanType, data) {
    switch(loanType) {
        case "loanMe":
            let loanme = await loanmeEligibility(data);
            return loanme;
        case "pension":
            let pension = await pensionEligibility(data);
            return pension;
        case "pencash":
            let pencash = await pencashEligibility(data);
            return pencash;
        case "capitalme":
            let capitalme = await capitalmeEligibility(data);
            return capitalme;
        case "cediscredit":
            let credit = await creditEligibility(data);
            return credit;
        case "cashme":
            let cashme = await cashmeEligibility(data);
            return cashme;
        case "investment":
            let investment = await investmentEligibility(data);
            return investment;
        case "payroll":
            let payroll = await payrollEligibility(data);
            return payroll;
        default:
            return
    }
}

// loanMe eligibility check
async function loanmeEligibility(data) {

    // destructuring data
    const { employedCurrently, automatedPaymentsStatus, incomeAmt, yearsAtWork, debtAmt, momoBalance, defaultedLoanStatus, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, savingHabits, typeOfInsurance, eligibleSavingsHabit, amountReq, paymentDuration, paymentPlan } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // new income engine calculations
    let expense = Math.round((45 / 100) * income);
    let educationalCourse = Math.round((1 / 100) * income);
    let familySupport = Math.round((1 / 100) * income);
    let toBuilding = Math.round((1 / 100) * income);
    let medicalBills = Math.round((1 / 100) * income);
    let personalProjects = Math.round((1 / 100) * income);
    let savingsAmount = Math.round((5 / 100) * income);

    // get years are work conversion
    let numOfYearsAtWork = workYears[yearsAtWork];

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // get average cashflow of client
    let average;
    if(income <= 2000) average = "below2k";
    if(income >= 2001 && income <= 5000) average = "max5k";
    if(income >= 5000 && income <= 10000) average = "max10k";
    if(income >= 10001) average = "above10k";
    let cashFlow = incomeScores[average];


    // get client scoring
    let scoreRet = await getLoanMeScore(scoringDTI, cashFlow, savingHabits, numOfYearsAtWork, automatedPaymentsStatus, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus, momoBalance);
    let score = scoreRet.score;
    console.log(`score: ${score}`);

    let repeatCustomer = "Yes"; // get this value by counting number of client loans in system
    let creditStatus = repeatCustomer === "Yes" && score <= 70 ? "Yes" : repeatCustomer !== "Yes" && score <= 80 ? "Yes" : "No";

    // calculate loan interest rate - monthly
    let monthlyInterest = await getCustomerRate(score, loanProducts.loanme, creditStatus);

    let savingsCalc = Number(income - expense - debt - educationalCourse - familySupport - toBuilding - medicalBills - personalProjects - savingsAmount).toFixed(2);
    let clientFinancies = {
        income : income,
        expense : debt,
        savings : savingsCalc,
    }

    // set client insurance eligibility
    let insuranceStatus = typeOfInsurance === "None" ? "No" : "Yes";

    // get client loan eligible amount
    let amountEligible = await getLoanMeAmountEligible(monthlyInterest, paymentDuration, clientFinancies, score, insuranceStatus, automatedPaymentsStatus, eligibleSavingsHabit, employedCurrently);
    console.log(`Amount eligible: ${amountEligible}`);

    amountEligible = Math.max(0, Math.min(amountEligible, loanAmtLimits.loanme));

    let loanAmt = Math.min(parseFloat(amountReq), amountEligible);
    console.log(`Amount requested: ${amountReq} and loan amount: ${loanAmt}`)

    // get client payment amount and charges
    let paymentObj = await getLoanMePaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.loanme ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }

}


// pension eligibility check
async function pensionEligibility(data){

    // destructuring data
    const { employedCurrently, incomeAmt, debtAmt, automatedPaymentsStatus, pensionAsset, pensionContYrs, pensionContOneYr, avgPensionCont, collateralType, yearsAtWork, defaultedLoanStatus, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, typeOfInsurance, eligibleSavingsHabit, amountReq, paymentPlan, paymentDuration, pensionState } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // new income engine calculations
    let expense = Math.round((45 / 100) * income);
    let educationalCourse = Math.round((1 / 100) * income);
    let familySupport = Math.round((1 / 100) * income);
    let toBuilding = Math.round((1 / 100) * income);
    let medicalBills = Math.round((1 / 100) * income);
    let personalProjects = Math.round((1 / 100) * income);
    let savingsAmount = Math.round((5 / 100) * income);

    // get years are work conversion
    let numOfYearsAtWork = workYears[yearsAtWork];

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // get average cashflow of client
    let average;
    if(income <= 2000) average = "below2k";
    if(income >= 2001 && income <= 5000) average = "max5k";
    if(income >= 5000 && income <= 10000) average = "max10k";
    if(income >= 10001) average = "above10k";
    let cashFlow = incomeScores[average];

    // calculate pension balance from pensionAsset variable
    let pensionBalance;
    if(parseFloat(pensionAsset) >= 2000 && parseFloat(pensionAsset) <= 4999) pensionBalance = "max5k";
    if(parseFloat(pensionAsset) >= 5000 && parseFloat(pensionAsset) <= 9999) pensionBalance = "max10k";
    if(parseFloat(pensionAsset) >= 10000 && parseFloat(pensionAsset) <= 19999) pensionBalance = "max20k";
    if(parseFloat(pensionAsset) >= 20000) pensionBalance = "above20k";

    // get client scoring
    let scoreRet = await getPensionScore(scoringDTI, cashFlow, pensionBalance, pensionContYrs, pensionContOneYr, avgPensionCont, automatedPaymentsStatus, numOfYearsAtWork, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus);
    let score = scoreRet.score;
    console.log(`score: ${score}`);

    let creditStatus = (score >= 40 && score <= 60) ? "Yes" : "No";
    creditStatus = creditStatus !== "No" ? creditStatus : pensionState;

    // calculate loan interest rate - monthly
    let monthlyInterest = await getCustomerRate(score, loanProducts.pension, creditStatus);

    let savingsCalc = Number(income - expense - debt - educationalCourse - familySupport - toBuilding - medicalBills - personalProjects - savingsAmount).toFixed(2);
    let clientFinancies = {
        income : income,
        expense : debt,
        savings : savingsCalc,
    }

    // set client insurance eligibility
    let insuranceStatus = typeOfInsurance === "None" ? "No" : "Yes";

    // get client loan eligible amount
    let amountEligible = creditStatus === "Yes" ? 
        // creditStatus is Yes
        await getPensionCreditEligibility(monthlyInterest, paymentDuration, income, debt, pensionAsset, collateralType, score, amountReq) :
        // creditStatus is unsecured
        creditStatus === "unsecured" ? await getLoanMeAmountEligible(monthlyInterest, paymentDuration, clientFinancies, score, insuranceStatus, automatedPaymentsStatus, eligibleSavingsHabit, employedCurrently) :
        // creditStatus is secured
        await getPensionSecuredEligibility(monthlyInterest, paymentDuration, income, debt, pensionAsset, collateralType, score, amountReq, "Normal");

    // 80% of pension asset now becomes the loan amount
    let pensionAssetAmt = parseFloat(pensionAsset);
    let pensionAssetAmt80 = Math.round((80 / 100) * pensionAssetAmt);
    let maxLoanAmt = amountEligible >= loanAmtLimits.pension ? loanAmtLimits.pension : amountEligible;
    let amtApproved = amountReq >= maxLoanAmt ? maxLoanAmt : amountReq;

    // final amount eligible (loanAmtLimits.pension / 2)
    let loanLimit = Math.round((loanAmtLimits.pension / 2));
    amountEligible = creditStatus !== "No" ? amountEligible : creditStatus === "unsecured" ? (amtApproved <= loanLimit) ? amtApproved : Math.min(pensionAssetAmt80, loanAmtLimits.pension, amtApproved) : amountEligible;

    let loanAmt = Math.round(Math.min(parseFloat(amountReq), amountEligible));
    console.log(`Amount requested: ${amountReq} and loan amount: ${loanAmt}`)

    // get client payment amount and charges
    let paymentObj = await getPensionPaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.pension ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }
}


// pencash eligibility check
async function pencashEligibility(data){

    // destructuring data
    const { incomeAmt, debtAmt, automatedPaymentsStatus, pensionAsset, pensionContYrs, pensionContOneYr, avgPensionCont, yearsAtWork, defaultedLoanStatus, loanPaymentHistory, billPaymentHistory, billsHistoryStatus, amountReq, paymentPlan, paymentDuration } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // new income engine calculations
    let expense = Math.round((45 / 100) * income);
    let educationalCourse = Math.round((1 / 100) * income);
    let familySupport = Math.round((1 / 100) * income);
    let toBuilding = Math.round((1 / 100) * income);
    let medicalBills = Math.round((1 / 100) * income);
    let personalProjects = Math.round((1 / 100) * income);
    let savingsAmount = Math.round((5 / 100) * income);

    // get years are work conversion
    let numOfYearsAtWork = workYears[yearsAtWork];

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // get average cashflow of client
    let average;
    if(income <= 2000) average = "below2k";
    if(income >= 2001 && income <= 5000) average = "max5k";
    if(income >= 5000 && income <= 10000) average = "max10k";
    if(income >= 10001) average = "above10k";
    let cashFlow = incomeScores[average];

    // calculate pension balance from pensionAsset variable
    let pensionBalance;
    if(parseFloat(pensionAsset) >= 2000 && parseFloat(pensionAsset) <= 4999) pensionBalance = "max5k";
    if(parseFloat(pensionAsset) >= 5000 && parseFloat(pensionAsset) <= 9999) pensionBalance = "max10k";
    if(parseFloat(pensionAsset) >= 10000 && parseFloat(pensionAsset) <= 19999) pensionBalance = "max20k";
    if(parseFloat(pensionAsset) >= 20000) pensionBalance = "above20k";

    // get client scoring
    let scoreRet = await getPensionScore(scoringDTI, cashFlow, pensionBalance, pensionContYrs, pensionContOneYr, avgPensionCont, automatedPaymentsStatus, numOfYearsAtWork, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus);
    let score = scoreRet.score;
    console.log(`score: ${score}`);

    let creditStatus = "";

    // calculate loan interest rate - monthly
    let monthlyInterest = await getCustomerRate(score, loanProducts.pencash, creditStatus);

    let savingsCalc = Number(income - expense - debt - educationalCourse - familySupport - toBuilding - medicalBills - personalProjects - savingsAmount).toFixed(2);
    let clientFinancies = {
        income : income,
        expense : debt,
        savings : savingsCalc,
    }

    // get client loan eligible amount
    let amountEligible = await getPenCashEligibility(monthlyInterest, paymentDuration, clientFinancies, score, "Yes", "Yes", "Yes", "Yes");

    amountEligible = Math.max(0, Math.min(amountEligible, loanAmtLimits.pensionCashLoanLimit));

    // 80% of pension asset now becomes the loan amount
    let pensionAssetAmt = parseFloat(pensionAsset);
    let pensionAssetAmt80 = Math.round((80 / 100) * pensionAssetAmt);
    amountEligible = (amountReq > amountEligible) ? amountEligible : amountReq;

    // final amount eligible (loanAmtLimits.pension / 2)
    let loanLimit = Math.round((loanAmtLimits.pensionCashLoanLimit / 2));
    amountEligible = (amountEligible <= loanLimit) ? amountEligible : pensionAssetAmt80;

    let loanAmt = Math.round(Math.min(parseFloat(amountReq), amountEligible));
    console.log(`Amount requested: ${amountReq} and loan amount: ${loanAmt}`)

    // get client payment amount and charges
    let paymentObj = await getPensionPaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.pencash ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }
}


// capitalme eligibility check
async function capitalmeEligibility(data){

    // destructuring data
    const { employedCurrently, incomeAmt, debtAmt, automatedPaymentsStatus, yearsInOperation, typeOfInsurance, transFreq, consistTrans, defaultedLoanStatus, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, savingHabits, eligibleSavingsHabit, amountReq, paymentPlan, paymentDuration } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // new income engine calculations
    let monthlyCostOfSales = Math.round((30 / 100) * income);
    let businessOperatingCost = Math.round((10 / 100) * income);
    let expense = Math.round((5 / 100) * income);
    let educationalCourse = Math.round((1 / 100) * income);
    let familySupport = Math.round((1 / 100) * income);
    let toBuilding = Math.round((1 / 100) * income);
    let medicalBills = Math.round((1 / 100) * income);
    let personalProjects = Math.round((1 / 100) * income);
    let savingsAmount = Math.round((5 / 100) * income);

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // get average cashflow of client
    let average;
    if(income <= 2000) average = "below2k";
    if(income >= 2001 && income <= 4000) average = "max4k";
    if(income >= 4001 && income <= 6000) average = "max6k";
    if(income >= 6001 && income <= 8000) average = "max8k";
    if(income >= 8001) average = "above8k";

    // get client momo-balance
    let momoBalance;
    if(income <= 3000) momoBalance = "below3k";
    if(income >= 3001 && income <= 5000) momoBalance = "max5k";
    if(income >= 5001 && income <= 10000) momoBalance = "max10k";
    if(income >= 10001 && income <= 15000) momoBalance = "max15k";
    if(income >= 15001) momoBalance = "above15k";

    let cashFlow = capitalMeMomoAverage[average];
    let workingCapital = capitalMeIncomeRange[momoBalance];

    let customerInputs = {
        workingCapital, cashFlow, scoringDTI, transFreq, consistTrans, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus
    }

    let companyInputs = {
        yearsInOperation
    }

    let industryInputs = {
        savingHabits, automatedPaymentsStatus
    }

    // get client scoring
    let score = await getCapitalMeScore(customerInputs, companyInputs, industryInputs);
    console.log(`score: ${score}`);

    // repeat customer status
    let repeatCustomer = "Yes"; // get this value by counting number of client loans in system
    let creditStatus = repeatCustomer === "Yes" && score <= 70 ? "Yes" : repeatCustomer !== "Yes" && score <= 80 ? "Yes" : "No";

    // calculate loan interest rate - monthly
    let monthlyInterest = await getCustomerRate(score, loanProducts.capitalme, creditStatus);

    let savingsCalc = Number(income - monthlyCostOfSales - businessOperatingCost - expense - debt - educationalCourse - familySupport - toBuilding - medicalBills - personalProjects - savingsAmount).toFixed(2);
    let clientFinancies = {
        income : income,
        expense : debt,
        savings : savingsCalc,
    }

    // set client insurance eligibility
    let insuranceStatus = typeOfInsurance === "None" ? "No" : "Yes";

    // get client loan eligible amount
    let amountEligible = creditStatus === "Yes" ? 
        // capitalme cediscredit
        await getCapitalMeCreditEligibility(monthlyInterest, paymentDuration, income, debt, amountReq) : 
        // capitalme normal
        await getCapitalMeAmountEligibility(monthlyInterest, paymentDuration, clientFinancies, score, insuranceStatus, eligibleSavingsHabit, automatedPaymentsStatus, employedCurrently);
    
    amountEligible = creditStatus === "Yes" ? amountEligible : Math.max(0, Math.min(amountEligible, loanAmtLimits.businessLoanLimit));

    amountEligible = creditStatus === "Yes" ? amountEligible : Math.min(parseFloat(amountReq), amountEligible);

    let loanAmt = amountEligible;

    // get client payment amount and charges
    let paymentObj = await getCapitalMePaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.capitalme ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }

}


async function creditEligibility(data){

    // destructuring data
    const { incomeAmt, debtAmt, yearsInOperation, averageMomoBalance, incomeConsist, loanPaymentHistory, billPaymentHistory, loanHistoryStatus, defaultedLoanStatus, numTransPerMonth, minAmtPerMonth, automatedPaymentsStatus, paymentPlan } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // new income engine calculations
    let expenses = (55 / 100) * income;
    let DIR = roundNum(roundNum(100 * expenses / parseFloat(income), 1));
    console.log(`DIR: ${DIR}`);

    // debt
    let debt = parseFloat(debtAmt);

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // years in operation
    let yearsAtWork = workYears[yearsInOperation];

    // get score from credit score engine
    let scoreRet = await getCedisCreditScore(scoringDTI, averageMomoBalance, automatedPaymentsStatus, numTransPerMonth, minAmtPerMonth, incomeConsist, yearsAtWork, loanPaymentHistory, billPaymentHistory, loanHistoryStatus, defaultedLoanStatus);
    let score = scoreRet.score;
    console.log(`score: ${score}`);

    // get client loan eligible amount
    let amountEligible = await getCedisCreditEligibility(income, debt, DIR, score);
    console.log(`Amount eligible: ${amountEligible}`);

    let loanAmt = Math.min(parseFloat(amountEligible), loanAmtLimits.creditCashLoanLimit);
    console.log(`Loan amount: ${loanAmt}`);

    // calculate loan interest rate - monthly (since no loan pricing, interest rate is fixed)
    let monthlyInterest = interestRate.cediscredit;

    // get client payment amount and charges
    let paymentObj = await getCreditPaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.cediscredit ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }

}


async function cashmeEligibility(data){

    // destructuring data
    const { incomeAmt, debtAmt, momo_transactions, numOfTransactions, yearsInOperation, typeOfInsurance, custRelTime, defaultedLoanStatus, billsHistory, billsHistoryStatus, savingHabits, amountReq, paymentPlan, paymentDuration } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // new income engine calculations
    let expense = Math.round((45 / 100) * income);
    let educationalCourse = Math.round((1 / 100) * income);
    let familySupport = Math.round((1 / 100) * income);
    let toBuilding = Math.round((1 / 100) * income);
    let medicalBills = Math.round((1 / 100) * income);
    let personalProjects = Math.round((1 / 100) * income);
    let savingsAmount = Math.round((5 / 100) * income);

    let total_expenses = expense + debt + educationalCourse + familySupport + toBuilding + medicalBills + personalProjects + savingsAmount;

    // get client available funds
    let availableFunds = income - total_expenses;

    // get client income range
    let average;
    if(income <= 500) average = "below500";
    if(income >= 501 && income <= 1000) average = "max1k";
    if(income >= 1001 && income <= 2000) average = "max2k";
    if(income >= 2001 && income <= 3000) average = "max3k";
    if(income >= 3001) average = "above3k";
    let cashFlow = cashMeIncomeFlow[average];
    let workingCapital = cashMeIncomeFlow[average];

    // get years are work conversion
    let numOfYearsAtWork = yearsInBusiness[yearsInOperation];

    // get client insurance status
    let insuranceHabit = typeOfInsurance === "None" ? "No" : "Yes";

    // get client credit score
    let scoreRet = await getCashMeScore(workingCapital, cashFlow, momo_transactions, numOfTransactions, custRelTime, defaultedLoanStatus, billsHistory, billsHistoryStatus, numOfYearsAtWork, savingHabits, insuranceHabit);
    let score = scoreRet.score;
    console.log(`client credit score is: ${score}`);

    // get interest rate for cashMe (this is fixed)
    let monthlyInterest = interestRate.cashme;

    // get client eligible loan amount
    let amountEligible = await getCashMeEligibility(monthlyInterest, availableFunds, paymentDuration);
    amountEligible = Math.max(0, Math.min(amountEligible, loanAmtLimits.cashmeLoanLimit));

    let loanAmt = Math.min(parseFloat(amountReq, amountEligible));

    // get client payment amount and charges
    let paymentObj = await getCashMePaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.cashme ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }

}


async function investmentEligibility(data){

    // destructuring data
    const { incomeAmt, debtAmt, automatedPaymentsStatus, yearsAtWork, defaultedLoanStatus, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, collateralType, collateralBal, investSize, investFreq, savingsPerc, savingsFreq, transactionFreq, transactionSize, accountAge, activeFreq, accountBalAvg, amountReq, paymentPlan, paymentDuration, loanKind } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // years at work
    let numOfYearsAtWork = workYears[yearsAtWork];

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // get client income range
    let average;
    if(income <= 2000) average = "below2k";
    if(income >= 2001 && income <= 5000) average = "max5k";
    if(income >= 5001 && income <= 10000) average = "max10k";
    if(income >= 10001) average = "above10k";
    let cashFlow = incomeScores[average];

    // get client credit score
    let scoreRet = await getInvestmentScore(scoringDTI, cashFlow, investSize, investFreq, savingsPerc, savingsFreq, transactionFreq, transactionSize, accountAge, activeFreq, accountBalAvg, numOfYearsAtWork, automatedPaymentsStatus, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus);
    let score = scoreRet.score;
    console.log(`client credit score is: ${score}`);

    // get interest rate for investment
    let investmentPartner = loanKind === "achieve" ? "achieve" : "petra";
    let monthlyInterest = await getCustomerRate(score, loanProducts.invest, investmentPartner);

    // get client eligible loan amount
    let amountEligible = await getInvestmentEligibility(monthlyInterest, paymentDuration, income, debt, collateralBal, collateralType, score);
    amountEligible = Math.max(0, Math.min(amountEligible, loanAmtLimits.investCashLoanLimit));

    let loanAmt = Math.min(parseFloat(amountReq), amountEligible);

    // get client payment amount and charges
    let paymentObj = await getInvestmentPaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.investment ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }

}


// Payroll eligibility check
async function payrollEligibility(data){

    // destructuring data
    const { employerMOU, incomeAmt, debtAmt, automatedPaymentsStatus, yearsAtWork, defaultedLoanStatus, billsHistoryStatus, loanPaymentHistory, billPaymentHistory, amountReq, paymentPlan, paymentDuration, loanKind } = data;

    // get client income amount
    let income = parseFloat(incomeAmt);

    // get client debt amount
    let debt = parseFloat(debtAmt);

    // get years are work conversion
    let numOfYearsAtWork = workYears[yearsAtWork];

    // separate scoring dti
    let scoringDTI = Math.round(roundNum(100 * debt / incomeAmt, 1));

    // get client income range
    let average;
    if(income <= 2000) average = "below2k";
    if(income >= 2001 && income <= 5000) average = "max5k";
    if(income >= 5001 && income <= 10000) average = "max10k";
    if(income >= 10001) average = "above10k";
    let cashFlow = incomeScores[average];

    // get client momo balance
    let momoBalance;
    if(income <= 3000) momoBalance = "below3k";
    if(income >= 3001 && income <= 5000) momoBalance = "max5k";
    if(income >= 5001 && income <= 10000) momoBalance = "max10k";
    if(income >= 10001 && income <= 15000) momoBalance = "max15k";
    if(income >= 15001) momoBalance = "above15k";

    // get client credit score
    let scoreRet = await getPayrollScore(loanKind, scoringDTI, cashFlow, loanKind === "Achieve Customer" ? employerMOU : momoBalance, numOfYearsAtWork, automatedPaymentsStatus, loanPaymentHistory, defaultedLoanStatus, billPaymentHistory, billsHistoryStatus);
    let score = loanKind === "Achieve Customer" ? scoreRet.score : 100;
    console.log(`client credit score is: ${score}`);

    // get interest rate for payroll
    let monthlyInterest = await getCustomerRate(score, loanProducts.employer, "");

    // get client eligible loan amount
    let amountEligible = await getPayrollEligibility(monthlyInterest, paymentDuration, income, debt, score, amountReq);
    amountEligible = Math.max(0, Math.min(amountEligible, loanAmtLimits.employerLoanLimit));

    let loanAmt = Math.min(parseFloat(amountReq), amountEligible);
    console.log(`Loan amount: ${loanAmt}`);

    // get client payment amount and charges
    let paymentObj = await getPayrollPaymentValues(loanAmt, data, monthlyInterest);

    return { 
        status : score < creditScoreThresholds.employer ? "rejected" : loanAmt === 0 ? "rejected" : "accepted",
        loanAmt,
        score,
        interestRate : monthlyInterest,
        paymentFreq : paymentPlan,
        paymentObj
    }

}

export {
    preCheckLoanEligibility
}