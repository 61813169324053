import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { FormInput, FormSelect, FormUpload, Button, Modal, EligCheck, OtherDocsUpload } from "components"
import { optionsYN, missedPaymentOptions, purposeOfLoanOpts, paymentPlanOpts, paymentDurationOpts, incomeFrequencyOpts, automaticPaymentOpts, relationshipManagers, plocYearsInBusinessOpts, transPerMonthOpts, minimumAmtPerTransOpts, averageBalanceOpts, incomeConsistencyOpts, billPaymentOptions } from 'components/misc/constants';
import { preCheckLoanEligibility } from '../../misc/loanPreChecks';
import { getDateAfterTimeframe, nextMonthStartDate } from 'components/misc/functions';

function Credit({ client }) {
    
    const fullName = client?.otherNames + " " + client?.surname;

    let [isOpen, setIsOpen] = useState(false);

    const closeModal = () => {
        setIsOpen(false)
    }
    
    const openModal = () => {
        setIsOpen(true)
    }

    const [incomeAmt, setIncomeAmt] = useState('');
    const [debtAmt, setDebtAmt] = useState('');
    const [yearsInOperation, setYearsInOperation] = useState('');
    const [averageMomoBalance, setAverageMomoBalance] = useState('');
    const [incomeConsist, setIncomeConsist] = useState('');
    const [loanPaymentHistory, setLoanPaymentHistory] = useState('');
    const [billPaymentHistory, setBillPaymentHistory] = useState('');
    const [billsOption, setBillsOption] = useState('');
    const [loanHistoryStatus, setLoanHistoryStatus] = useState('');
    const [loanDefaultStatus, setLoanDefaultStatus] = useState('');
    const [defaultedLoanStatus, setDefaultedLoanStatus] = useState('');
    const [uptodateBillsStatus, setUptodateBillsStatus] = useState('');
    const [numTransPerMonth, setNumTransPerMonth] = useState('');
    const [minAmtPerMonth, setMinAmtPerMonth] = useState('');
    const [automatedPaymentsStatus, setAutomatedPaymentsStatus] = useState('');
    const [purpose, setPurpose] = useState('');
    const [paymentPlan, setPaymentPlan] = useState('');
    const [paymentDuration, setPaymentDuration] = useState('');
    const [repaySource, setRepaySource] = useState('');
    const [freqIncome, setFreqIncome] = useState('');
    const [autoDeduction, setAutoDeduction] = useState('');
    const [incomeProof, setIncomeProof] = useState('');
    const [residenceProof, setResidenceProof] = useState('');
    const [bankOrMomoStatement, setBankOrMomoStatement] = useState('');
    const [otherDocs, setOtherDocs] = useState([]);
    const [startDate, setStartDate] = useState(getDateAfterTimeframe('daily'));
    const [relManager, setRelManager] = useState('');
    const [guarantorFirstName, setGuarantorFirstName] = useState('');
    const [guarantorLastName, setGuarantorLastName] = useState('');
    const [guarantorPhone, setGuarantorPhone] = useState('');
    const [guarantorAddress, setGuarantorAddress] = useState('');
    const [guarantorRelationship, setGuarantorRelationship] = useState('');
    const guarantorIdType = 'GhanaCard';
    const [guarantorIdNumber, setGuarantorIdNumber] = useState('');
    const [guarantorDigitalAddress, setGuarantorDigitalAddress] = useState('');
    const [guarantorDob, setGuarantorDob] = useState('');
    const signedName = fullName;
    const [preCheckData, setPreCheckData] = useState({}); 
    const [processing, setProcessing] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let data = { incomeAmt, debtAmt, yearsInOperation, averageMomoBalance, incomeConsist, loanPaymentHistory, billPaymentHistory, billsOption, loanHistoryStatus, loanDefaultStatus, defaultedLoanStatus, uptodateBillsStatus, numTransPerMonth, minAmtPerMonth, automatedPaymentsStatus, purpose, paymentPlan, paymentDuration, repaySource, freqIncome, autoDeduction, incomeProof, residenceProof, bankOrMomoStatement, otherDocs, relManager, startDate, guarantorFirstName, guarantorLastName, guarantorPhone, guarantorAddress, guarantorRelationship, guarantorIdType, guarantorIdNumber, guarantorDigitalAddress, guarantorDob, signedName }

        // precheck loan application eligibility
        let response = await preCheckLoanEligibility("cediscredit", data);
        setPreCheckData({ ...response, data });

        setTimeout(() => {
            setProcessing(false);
            openModal();
        }, 3000);
    }


    return (
        <>
        <div className='display_card'>
            <form className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-2' onSubmit={handleRequest}>
                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Income Information
                    </h3>
                </div>

                <FormInput 
                    label='Provide your monthly regular income'
                    name='incomeAmt'
                    id="incomeAmt"
                    type='number'
                    value={incomeAmt}
                    setValue={setIncomeAmt}
                    required={true}
                />

                <FormInput 
                    label='Provide your monthly debt expenses'
                    name='debtAmt'
                    id="debtAmt"
                    type='number'
                    value={debtAmt}
                    setValue={setDebtAmt}
                    required={true}
                />

                <FormSelect 
                    label='How long have you been operating this business in this location?'
                    name='yearsInOperation'
                    id="yearsInOperation"
                    content={plocYearsInBusinessOpts}
                    value={yearsInOperation}
                    setValue={setYearsInOperation}
                    required={true}
                />

                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Momo Statemennt Information
                    </h3>
                </div>

                <FormSelect 
                    label='What is your average 3 months mobile money balance?'
                    name="averageMomoBalance"
                    id="averageMomoBalance"
                    content={averageBalanceOpts}
                    value={averageMomoBalance}
                    setValue={setAverageMomoBalance}
                    required={true}
                />

                <FormSelect 
                    label='How would you describe the consistency of your mobile money transactions in the past 3 months?'
                    name="incomeConsist"
                    id="incomeConsist"
                    content={incomeConsistencyOpts}
                    value={incomeConsist}
                    setValue={setIncomeConsist}
                    required={true}
                />

                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Loan &amp; Bill Payment Behaviour Information
                    </h3>
                </div>

                <FormSelect 
                    label='Do you have 3 recent months loan payment history?'
                    name='loanPaymentHistory'
                    id="loanPaymentHistory"
                    content={optionsYN}
                    value={loanPaymentHistory}
                    setValue={setLoanPaymentHistory}
                    required={true}
                />

                <FormSelect 
                    label='Do you have 3 recent months bills payment history?'
                    name='billPaymentHistory'
                    id="billPaymentHistory"
                    content={optionsYN}
                    value={billPaymentHistory}
                    setValue={setBillPaymentHistory}
                    required={true}
                />

                <FormSelect 
                    label='What are the bills that you are responsible for paying?'
                    name='billsOption'
                    id="billsOption"
                    content={billPaymentOptions}
                    value={billsOption}
                    setValue={setBillsOption}
                    required={true}
                />

                <FormSelect 
                    label='What is the length of history of your loan payment?'
                    name="loanHistoryStatus"
                    id="loanHistoryStatus"
                    content={missedPaymentOptions}
                    value={loanHistoryStatus}
                    setValue={setLoanHistoryStatus}
                    required={true}
                />

                <FormSelect 
                    label='Have you ever defaulted on or missed a loan payment?'
                    name='loanDefaultStatus'
                    id="loanDefaultStatus"
                    content={optionsYN}
                    value={loanDefaultStatus}
                    setValue={setLoanDefaultStatus}
                    required={true}
                />

                <FormSelect 
                    label='How many times did you miss payment on your loan or bill payments?'
                    name='defaultedLoanStatus'
                    id="defaultedLoanStatus"
                    content={missedPaymentOptions}
                    value={defaultedLoanStatus}
                    setValue={setDefaultedLoanStatus}
                    required={true}
                />

                <FormSelect 
                    label='Are you currently up to date on payment of your electric bill?'
                    name="uptodateBillsStatus"
                    id="uptodateBillsStatus"
                    content={optionsYN}
                    value={uptodateBillsStatus}
                    setValue={setUptodateBillsStatus}
                    required={true}
                />

                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Scoring Information
                    </h3>
                </div>

                <FormSelect 
                    label='How many mobile money transactions do you make on average per month?'
                    name="numTransPerMonth"
                    id="numTransPerMonth"
                    content={transPerMonthOpts}
                    value={numTransPerMonth}
                    setValue={setNumTransPerMonth}
                    required={true}
                />

                <FormSelect 
                    label='What is the minimum amount per transaction that you typically make in a month?'
                    name="minAmtPerMonth"
                    id="minAmtPerMonth"
                    content={minimumAmtPerTransOpts}
                    value={minAmtPerMonth}
                    setValue={setMinAmtPerMonth}
                    required={true}
                />

                <FormSelect 
                    label='Do you have automated loan / bill payments set up?'
                    name='automatedPaymentsStatus'
                    id="automatedPaymentsStatus"
                    content={optionsYN}
                    value={automatedPaymentsStatus}
                    setValue={setAutomatedPaymentsStatus}
                    required={true}
                />

                <div className='col-span-full'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Loan Information
                    </h3>
                </div>

                <FormSelect 
                    label='What is the purpose of the loan?'
                    name='purpose'
                    id="purpose"
                    content={purposeOfLoanOpts}
                    value={purpose}
                    setValue={setPurpose}
                    required={true}
                />

                <FormSelect 
                    label='What is the desired payment plan?'
                    name='paymentPlan'
                    id="paymentPlan"
                    content={paymentPlanOpts.slice(0, 1)}
                    value={paymentPlan}
                    setValue={setPaymentPlan}
                    required={true}
                />

                <FormSelect 
                    label='What is the desired payment duration?'
                    name='paymentDuration'
                    id="paymentDuration"
                    content={paymentDurationOpts.slice(0, 3)}
                    value={paymentDuration}
                    setValue={setPaymentDuration}
                    required={true}
                />

                <FormInput 
                    label='Provide your source of income for loan repayment'
                    name='repaySource'
                    id="repaySource"
                    type='text'
                    value={repaySource}
                    setValue={setRepaySource}
                    required={true}
                />

                <FormSelect 
                    label='What is the frequency of your income?'
                    name='freqIncome'
                    id="freqIncome"
                    content={incomeFrequencyOpts}
                    value={freqIncome}
                    setValue={setFreqIncome}
                    required={true}
                />

                <FormSelect 
                    label='Please select your preferred automatic loan repayment method for your CedisPay loan'
                    name='autoDeduction'
                    id="autoDeduction"
                    content={automaticPaymentOpts.slice(2, 3)}
                    value={autoDeduction}
                    setValue={setAutoDeduction}
                    required={true}
                />

                <div>
                    <label htmlFor="startDate" className='form-label'>
                        Select loan start date
                    </label>
                    <input 
                        type="date"
                        name={"startDate"}
                        id={"startDate"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                        max={nextMonthStartDate()}
                        required={true}
                    />
                </div>

                <FormSelect 
                    label='Kindly select a relationship manager'
                    name='relManager'
                    id="relManager"
                    content={relationshipManagers}
                    value={relManager}
                    setValue={setRelManager}
                    required={true}
                />

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Guarantor Information
                    </h3>
                </div>

                <FormInput 
                    label="Guarantor's first name"
                    name='guarantorFirstName'
                    id="guarantorFirstName"
                    type='text'
                    value={guarantorFirstName}
                    setValue={setGuarantorFirstName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's last name"
                    name='guarantorLastName'
                    id="guarantorLastName"
                    type='text'
                    value={guarantorLastName}
                    setValue={setGuarantorLastName}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's phone number"
                    name='guarantorPhone'
                    id="guarantorPhone"
                    type='text'
                    value={guarantorPhone}
                    setValue={setGuarantorPhone}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's address"
                    name='guarantorAddress'
                    id="guarantorAddress"
                    type='text'
                    value={guarantorAddress}
                    setValue={setGuarantorAddress}
                    required={true}
                />

                <FormInput 
                    label="Your relationship to guarantor"
                    name='guarantorRelationship'
                    id="guarantorRelationship"
                    type='text'
                    value={guarantorRelationship}
                    setValue={setGuarantorRelationship}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's GhanaCard number"
                    name='guarantorIdNumber'
                    id="guarantorIdNumber"
                    type='text'
                    value={guarantorIdNumber}
                    setValue={setGuarantorIdNumber}
                    required={true}
                />

                <FormInput 
                    label="Guarantor's digital address"
                    name='guarantorDigitalAddress'
                    id="guarantorDigitalAddress"
                    type='text'
                    value={guarantorDigitalAddress}
                    setValue={setGuarantorDigitalAddress}
                    required={true}
                />

                <div>
                    <label htmlFor="guarantorDob" className='form-label'>
                        Guarantor's date of birth
                    </label>
                    <input 
                        type="date"
                        name={"guarantorDob"}
                        id={"guarantorDob"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={guarantorDob}
                        onChange={e => setGuarantorDob(e.target.value)}
                        min={"1930-01-01"}
                        max={new Date().toISOString().split('T')[0]}
                        required={true}
                    />
                </div>

                <div className='col-span-full mt-10'>
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Files to upload
                    </h3>
                </div>

                <FormUpload 
                    label='Upload proof of income'
                    name='incomeProof'
                    id="incomeProof"
                    value={incomeProof}
                    setValue={setIncomeProof}
                    required={true}
                />

                <FormUpload 
                    label='Upload proof of residence'
                    name='residenceProof'
                    id="residenceProof"
                    value={residenceProof}
                    setValue={setResidenceProof}
                    required={true}
                />

                <FormUpload 
                    label='Upload bank statement or momo statement'
                    name='bankOrMomoStatement'
                    id="bankOrMomoStatement"
                    value={bankOrMomoStatement}
                    setValue={setBankOrMomoStatement}
                    required={true}
                />

                <div className="col-span-full mt-10">
                    <h3 className='text-xl font-medium leading-6 dark:text-white'>
                        Attach other files 
                    </h3>
                </div>

                <OtherDocsUpload 
                    setValue={setOtherDocs}
                />


                <div className='col-span-full mt-10 p-10 bg-sky-50 rounded-md text-slate-700'>
                    <h3 className='text-2xl font-medium leading-6 mb-6'>
                        Authorization 
                    </h3>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Loan Authorization
                    </h4>   

                    <p className='mb-6'>
                        I, hereby apply for loans stated in Sections of this application the information I have provided hee is to the best of my knowledge true and correct. I authorize CedisPay Micro-Credit Enterprice to verify the correctneess of this information and also to obtain additional information if it deems necessary in evaluating my loan application.
                    </p>

                    <h4 className='text-lg font-medium leading-6 mb-2'>
                        Disclosure to Credit Reference Bureaus
                    </h4>   

                    <p className='mb-3'>
                        CedisPay will obtain information about you from the credit reference bureaus to check your credit status and identity. The bureaus will record our enquiries which may be seen by other institutions that make their own credit enquiries about you. CedisPay shall also disclose your credit transactions to credit reference bureaus in accordance with the <strong>Credit Reporting Regulations, 2020 (L.I 2394)</strong>
                    </p>
                </div>

                <FormInput 
                    label='Signed Name'
                    name='signedName'
                    id="signedName"
                    type='text'
                    value={signedName}
                    disabled={true}
                />

                <div className="col-span-full mt-6">
                    <div className="inline-flex items-center gap-2">
                        <Button 
                            btnText={"Check eligibility"}
                            btnType={"submit"}
                            btnClasses={"application-button w-max"}
                            processing={processing}
                        />
                    </div>
                </div>

            </form>
        </div>

        <Modal isOpen={isOpen} closeModal={closeModal}>
            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex items-center mb-4"
                >
                    Pre-Check Status: <span className={`ml-1 px-3 py-1.5 text-white font-medium rounded-full text-xs capitalize ${preCheckData?.status === 'accepted' ? 'bg-emerald-400' : preCheckData?.status === 'rejected' ? 'bg-red-400' : ''}`}>{preCheckData?.status}</span>
                </Dialog.Title>
                <EligCheck 
                    status={preCheckData?.status}
                    data={preCheckData}
                    loanType={"cediscredit"}
                    closeModal={closeModal}
                />
            </Dialog.Panel>
        </Modal>

        </>
    )
}

export default Credit