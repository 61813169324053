import { Topbar, Aside, Main, Files, Loader } from 'components';
import { CheckBadgeIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { dateToHumanFormat, employmentNatureOptions, occupationOptions } from 'components/misc/constants';
import { Link } from 'react-router-dom';
import { useGetUser } from 'hooks';

function Index() {

    const { profile, loading } = useGetUser();
    
    if(loading){
        return <Loader />
    }

    if(!profile?.isVerified){
        return (
            <main>
            <Topbar />
            
            <div className='pt-20 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    <div className='bg-red-100 text-red-700 mx-4 px-4 py-3 rounded relative flex flex-row items-center' role='alert'>
                        <ExclamationTriangleIcon 
                            className='h-5 w-5 text-red-700 mr-2'
                        />
                        <span className='block sm:inline'>
                        Please verify your account with <span className='font-bold'>Next of Kin Information</span> and <span className='font-bold'>GhanaCard Information</span> to be able to apply for a loan.
                        </span>

                        <Link to="/profile/edit" className='bg-red-50 text-red-700 text-sm font-medium inline-flex items-center px-2.5 py-1 rounded border border-red-200 ml-4 hover:bg-red-100'>
                            Verify Now
                        </Link>
                    </div>
                </Main>
            </div>
        </main>
        )
    }

    return (
        <main>
            <Topbar />
            
            <div className='pt-20 w-full flex overflow-hidden bg-light-bg dark:bg-dark-page'>
                <Aside />

                <Main>
                    <div className="display_card overflow-hidden">
                        <div className="flex items-center justify-between mb-3">
                            <h3 className="w-24 md:w-auto text-lg font-medium leading-6 text-gray-900 dark:text-white inline-flex items-center">
                                Personal Information 

                                <span className="bg-gray-50 text-gray-700 text-xs font-medium inline-flex items-center px-1.5 py-0.5 rounded border border-gray-200 ml-4">
                                    {profile?.isVerified ? (
                                    <>
                                    <CheckBadgeIcon 
                                        className='w-5 h-5 mr-1 text-blue-400'
                                    />
                                    Verified
                                    </>
                                    ) : (
                                    <>
                                    <CheckBadgeIcon 
                                        className='w-5 h-5 mr-1 text-gray-300'
                                    />
                                    Not Verified
                                    </>
                                    )}
                                    
                                </span>
                            </h3>

                            <Link to="/profile/edit" className='bg-gray-50 text-gray-700 text-base font-normal inline-flex items-center px-2.5 py-0.5 rounded border border-gray-200'>
                                Edit
                            </Link>
                        </div>
                        <div className="border-t dark:border dark:rounded-md border-gray-200 dark:border-white/[.2]">
                            <dl className='divide-y divide-white/[.2]'>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">First Name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.otherNames}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">Last Name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.surname}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Phone Number / Network
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.phone} / {profile?.network}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                        Email address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.email}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Gender
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0 capitalize">
                                        {profile?.gender}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Date of Birth
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {dateToHumanFormat(profile?.dob)}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Nationality
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0 capitalize">
                                        {profile?.nationality}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Place of Birth
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.placeOfBirth}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Residential Address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.address}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Digital Address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.digitalAddress}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Nearest Landmark
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.nearestLandmark}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Educational Level
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.education}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Occupation
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {occupationOptions.find(option => option.value === profile?.occupation)?.text}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Employment Nature
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {employmentNatureOptions.find(option => option.value === profile?.employNature)?.text}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Identification Type / Number
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0 inline-flex space-x-4 font-medium">
                                        {profile?.idType ? profile?.idType : "-"} / {profile?.idNumber ? profile?.idNumber : "-"}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Next of Kin's Full Name
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0 inline-flex space-x-4 font-medium">
                                        {profile?.nextOfKin}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Next of Kin's Phone Number
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.nextOfKinPhone}
                                    </dd>
                                </div>
                                <div className="bg-gray-50 dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Next of Kin's Address
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0 inline-flex space-x-4 font-medium">
                                        {profile?.nextOfKinAddress}
                                    </dd>
                                </div>
                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                    Relationship to Next of Kin
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 dark:text-slate-200 sm:col-span-2 sm:mt-0">
                                        {profile?.nextOfKinRelationship}
                                    </dd>
                                </div>

                                <div className="bg-white dark:bg-transparent px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 dark:text-slate-200">
                                        Uploaded Files &amp; IDs
                                    </dt>
                                    <Files files={profile?.uploadedFiles} />
                                </div>
                            </dl>
                        </div>
                    </div>
                </Main>
            </div>
        </main>
    )
}

export default Index